/**
 * @Global scss for GoG
 * @project     - GoG Personality
 * @author      - AHP Tech
 * @created_by  - K.M Foysal
 * @created_at  - 
 * @modified_by -
 */

/* fonts import */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,600|DM+Sans:700|Poppins:700,400");
/* fonts import -END */

/* reset, root */
* {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  padding: 0;
  // background-color: #000;
  margin: 0px;
  height: 100%;
  overflow-x: hidden !important;
  font-family: "Montserrat", sans-serif !important;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
}

a {
  text-decoration: none;
}

.pl-200 {
  padding-left: 200px;

  @media (max-width: 1200px) {
    padding-left: 100px;
  }

  @media (max-width: 1024px) {
    padding-left: 50px;
  }
}

.fs-48 {
  font-size: 48px;

  @media (max-width:1024px) {
    font-size: 36px;
  }
}

.fw-700 {
  font-weight: 700 !important;
}

.mt-50{
    margin-top: 50px !important;
}

.share-button-wrap { 
  .next-arrow.learn-more  {
    
    text-decoration:none;

  }
   

  .share-btn { 
    margin: 0 8px 0 0

  }
}

.signin-btn {
  max-width: 176px;
  width: 176px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #ff5603;
  box-shadow: 0px 5.062880992889404px 30.37728500366211px 0px
    rgba(255, 86, 3, 0.5);
  color: #fffeff;
  font-size: 19px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 24px;
  border: none;
  margin: 34px;
  text-decoration-line: none;
}
.share-btn:hover, 
.share-btn:active, 
.share-btn:focus, 
.next-arrow.learn-more:hover,
.next-arrow.learn-more:active,
.next-arrow.learn-more:focus, 
.signin-btn:hover,
.signin-btn:active,
.signin-btn:focus {
  background: #e04b00;
  text-decoration:none; 
  color: white;
}

.share-btn {
  max-width: 160px;
  width: 100%;
  height: 44px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ff5603;
  color: #fff;
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border: none;
  margin: 60px auto 0 auto;
  box-shadow: 0px 5.062880992889404px 18px 0px #892d00;
}

.signin-btn-boxshadow {
  box-shadow: 0px 5.062880992889404px 18px 0px #892d00;
}
.signin-btn.go-to-profile-btn {
  padding: 8px;
  line-height: 1.2;

} 
.desc{
         @media (max-width: 1024px) {
        font-size: 14px !important;
      }
    }
.icon-left {
  vertical-align: middle;
  margin-right: 5px;
  width: auto;
  height: 50px;
}

.bg-orange {
  background-color: #FF5603 !important;
  background-image: url(http://localhost:3000/static/media/Desktop-2-bg.d6e61eb….png);
  background-blend-mode: color-dodge;
}
.label-lightOrange {
  background: #FF9228;
  padding: 16px 0 16px 16px;
  color: #fff;
  margin: 16px 0;
}


.btn.prev-arrow.btn--backbtn {
  position: absolute;
  top: 8vw;
  right: initial;
  left: 2vw;
  z-index: 1000;
  background: rgba(255, 146, 41, 0.05);
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border: none;
  box-shadow: none;
}

.btn.prev-arrow.btn--backbtn:hover,
.btn.prev-arrow.btn--backbtn:active,
.btn.prev-arrow.btn--backbtn:focus {
  background: #ff9229;
}

.profileSummary {
  position: relative;
  width: 90%;
  margin: 32px auto;
  max-width: 560px;
  padding: 0;
  border-collapse: collapse;
  border: 1px solid #fb6d26;
  
  z-index:10000;
  border: none;
  margin-bottom: 0;
}

@media (min-width:480px){
  .profileSummary {
    width: 100%;
    max-width: initial;
  }
}
.profile_summary_table tbody tr > td:nth-child(odd) {
  background:#fb6d26;
  max-width: 40%;

}
.profile_summary_table tbody tr > td:nth-child(even) {
  max-width: 40%;
  max-height: 60px;
  overflow: hidden;

  span {
      text-overflow: ellipsis;
  }
}

.profileSummary > tbody > tr > td:nth-child(odd) {
  color: white;
  padding: 8px;
  background: #fb6d26;
  font-weight: bold;
}

table.profileSummary, 
.profileSummary  th, 
.profileSummary  tr {
  border-collapse: collapse !important;
  border: 1px solid #fb6d26;
  border: none;
}



.profileSummary  > tbody > tr > td:nth-child(even) {
  background: white;
    color: black;
    padding: 8px;
}
.profileSummary .col-12 {
  padding: 0 9px;
}

.profileSummary .nav.nav-tabs .nav-link.active {
  background: #fff !important;
  color: #fb6d26;
  font-weight: bold;
  border: 1px solid #EF6C00 !important;
}
.profileSummary .nav.nav-tabs .nav-link {
  background: #fb6d26 !important;
  color: white;
  font-weight: normal;
  border: 1px solid #EF6C00 !important;
}

.profileSummary .tab-content {
  background: #fb6d26;
  background:transparent; 
}

:root {
  --gogOrange1:#ff5603;
  --gogOrange2:#FF9228;
  --gogOrange3:#FCA34D;
}
.bg_tab_about-me {
  background-color: red;
}
.bg_tab_about-my-pref {
  background-color: blue;
}

.bg-transparent {
  background: transparent;
  border: none;
}
.bg-gogOrange1 {
  background: var(--gogOrange1);
  background: linear-gradient(127deg, #ff5603, #ffa168);
  border: none;
}
.bg-gogOrange2 {
  background: var(--gogOrange2);
}
.bg-gogOrange3 {
  background: var(--gogOrange3);
}

.tab-content .tab-pane .profile__stat--right span,
.tab-content .tab-pane .profile__stat--left span,
.tab-content .tab-pane .profile__stat--left {
  text-transform: lowercase;
  font-weight: bold;
    font-family: "DM Sans", Helvetica;
}
.profile__stat--icons.d-flex.justify-content-center {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  padding: 0;
}

.slide-up-modal-divider {
  background-color: rgb(207, 217, 222);
  width:36px;
  height: 5px;
  margin: 4px auto;
  position:relative;
}
.loggedout-notice,
.u-img--logo-left {
  left: 16px;
  right: 16px;
  position: relative;
  height: 8vw;
  display:inline;
  top: 10px;
  z-index: 10;
 
}
.loggedout-notice {
  position: absolute;
  background: slategrey;
  color: white;
  top: auto;
  top: 96px;
  display: block;
  width: 190px;
  border-radius: 8px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
}
 
@media (min-width: 623px){ 
  .u-img--logo-left {
      height: 62px;
  }
}
.testAnimation__wrap {
  position: absolute;
  z-index: 100000000;
  width: 60px;
  display: block;
  color: #fb722c;
  background: #1d1920;
  left: initial;
  top: 62vh;
  right: 2vw;
  margin: 8px auto;
  font-size:12px;
}
.testAnimation__select {
  position: relative;
  width: 59px;
  background: transparent;
  color: #fb722c;
  display: block;
  font-size: 11px;
  text-align: center;
  border: 1px solid #fb722c;
  padding: 6px;
}
.testBtn {
  position: absolute;
  top: 84vh;
  display: block;
  z-index: 100000000;
  background: white;
}

.testBtn-2 {
  top: 300px;
}

.testBtn-3 {
  top: 80vh;
}

.testBtn {
  position: relative;
  top: initial;
  display: block;
  z-index: 1000;
  left: initial;
  right: 0;
  padding: 5px;
  width: 59px;
  color: #fb722c;
  background: #1d1920;
  border: 1px solid;
  margin-top: 4px;
  font-size:11px;
  margin-right: 0;
} 

.certificate-seals {
  z-index: 9;
  position: absolute;
  right: 8px;
  width: 24vw;
  top: 36px;
  text-align: left;
}
.certificate-seal--larger,
.certificate-seal {
  width: 5.1vw;
  position: relative;
  display: inline-block;
  height: auto;
  margin: 0 0.4vw;
  -webkit-filter: opacity(0.6);
  filter: opacity(0.9);
}
.certificate-seal--larger {
 max-width: 200px;
 width:40%;
}
.u-gradient--style-1{
  background:radial-gradient(45deg, rgb(230, 229, 254),rgba(143, 68, 229, 0.937),rgb(236, 212, 246));
  
  background: radial-gradient(farthest-corner at 40px 40px,#ff853e, orange);
}
.workaround-button:before {
  width: 91%;
  width: -webkit-fill-available;
  height: 40px;
  height: -webkit-fill-available;

  display: block;
  content: attr(data-workaround);
  z-index: 5;
  border-radius: 6px;
  position: absolute;
  text-align: center;
  pointer-events:none;
  cursor:pointer;
  font-weight: bold;
  color: white;
  background: linear-gradient(45deg, #FF9800, #EF6C00);
  padding: 1.6vw 0;
}

.animated-character--wrap {
  position: absolute;
  z-index: 10000000000;
  height: 430px;
  width: 300px;
  overflow: hidden;
  top: 0;
  left: initial;
  right: 0;
  display:block;
  margin: 0 auto;
  background:transparent;
  margin-left: auto;
  pointer-events:none;
  margin-top: -370px;
  margin-right: -99px;
}
.animated-character {

  z-index: 10000;
  height: 430px;
  width: auto;
  top: 0;
  left: 0;
  right: initial;
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  margin-left: -70%;
  transition: transform 0.5s, -webkit-transform 0.5s;

}
.u-character--translate {
  transform: translateY(200px); /* Adjust as needed */
}
 
 
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.style-js-BlackBackground {
  background:black;
  color:snow;
  background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: top center;
   /*  background-image: url('bg/bg--41acdba6-4bb0-45d1-ac87-7e08675943c1.png');
    background-image: url('bg/PersonalityTestpg1.png');
    */
    background-image: url('../public/bg/bg--41acdba6-4bb0-45d1-ac87-7e08675943c1.png');
    background-size: auto 100vh;
  
    font-weight:bold;

  .test-title {
    color:white;
  }
  h2, h3, h4 {
    color:white;
  }
}

 
@media (min-width: 982px) {
  .style-js-BlackBackground {
    background-size: 100% auto;
  }
}
 

.main-content.style-js-BlackBackground:before {
  position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    background-color: rgb(189 115 63 / 71%);
    background-color: rgb(15 10 4 / 71%);
    display: block;
    content: ' ';
}
#sidenav-main {
  will-change: position, background-color, z-index;
   transition: all 0.3s ease;
   position:absolute;
   background:transparent;
   width:100%;
}
#sidenav-main.is-transparent-bar {
  background-color: transparent !important;
  z-index: 2 !important;
  position: absolute !important;
  width: 100% !important;
  margin-top: 54px;
}

#sidenav-main.is-transparent-bar .special-hover-opacity,
#sidenav-main.is-transparent-bar   .navbar-brand {
  display: none;
} 
 
#sidenav-main.is-transparent-bar + .main-content .special-hover-opacity {
  opacity:0.2;
}
#sidenav-main.is-transparent-bar .special-hover-opacity:hover,
#sidenav-main.is-transparent-bar + .main-content .special-hover-opacity:hover{
  opacity:1;
}

    


.style-js-BlackBackground.u-js-centered .personality-test-part__input-field {
  margin: 0 auto;
}
.style-js-BlackBackground.u-js-centered .personality-test-part {
  text-align:center;
}
.gog-js-iconResult {
  display: inline-block;
  width: 80px;
  height: auto;
  position: relative;
}
.createProfile__users-age-label {
padding: 2px 7px;
    line-height: 1.7;
    height: 37px;
    margin-bottom: 0;
    border-radius: 4px 0 0 4px;
    background: #491100;
}
.u-profile--notification-icon,
.u-profile--camera-icon {
  z-index: 100;
  border: none;
  width: 43px;
  margin: 0;
  background: #1e1d2200;
  opacity: 0.9;
}      
 

.unlock-conversion-wrapper {
  background: #222126;
}

.lockedFeatures-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 16px;
  gap: 16px;
  padding: 20px;

}

.lockedFeatures-grid--icon {
  width: 100%;
  height: auto;
  max-width: 120px;
  margin: 2px auto;
  left: 0;
  right: 0;
  display: block;
}
 

.lockedFeature.is-visible {
  height: auto;
  will-change: height;
  transition: 200ms ease-in 0s;
}
.lockedFeature {
  position: relative;
  color: snow;
  border-radius: 16px;
  background: snow;
  padding: 4px 8px;
  overflow: hidden;
  box-shadow: 5px 5px 9px black;
  height: 170px;


  h3 {
    position: relative;
    text-align: center;
    padding: 16px 0;
    display: block;
    font-size:16px;
    font-weight: bold;
    color: black;
    z-index:10;
  }

  p {
    position: relative;
    text-align: center;
    padding: 16px 0;
    display: block;
    font-weight: bold;
    color: black;
    font-size:12px;
  }
}

.lockedFeature-goldLockIcon {
  position: absolute;
  margin: 0 auto;
  height: 60px;
  width: 60px;
  top: 50%;
  z-index: 10;
  opacity: 0.8;
  left: 0;
  right: 0;


}



.lockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.98;
  transition: opacity 0.3s ease;
  background: radial-gradient(orange,orangered);
  z-index: 9;

}
 

.lockedFeature:hover .lockedOverlay {
  opacity: 0.4;
}
.lockedFeature:hover .lockedFeature-goldLockIcon {
  opacity: 0;
}

.unlockedFeature {
  filter: brightness(1.1);
}

.unlockedBadge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #00ff00;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
.btn-unlock-everything-cta {
  background-color: #21A62A;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  max-width: 400px;
}

.btn-unlock-everything-cta:hover {
  background-color: #1D8F26;  
}
.u-socialproof-video {
  max-width: 100%
}

.locked-section.d-flex {
  width: 100%;
}

.button-icon {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}

.hidden-label {
  display:none;
}

 
.u-SimplestFeedBack-form {
  padding: 24px;
  color: black;
  background: transparent;
  width: 100%;
  text-align: center;
  max-width: 500px;
}

.u-SimplestFeedBack-form-header {
  color: #ffeddd;
  font-size: 17px;
  font-weight: bold;
  padding: 6px 0;
}

.u-profile--icon-wrapper  {
  
  margin-left: 20%;
  margin-top: 5px;
  position: absolute;
    margin-top: -35px;
    left: 0;
    margin-left: 20px;
    width: 80px;
    

    .btn .img-fluid, svg {
      opacity:0.85;
    }
    .btn .img-fluid:hover, 
    svg:hover,
    .btn .img-fluid:focus-within, 
    svg:focus-within {
      opacity:1;
      filter:brightness(2);
    }
    .btn-secondary:focus-within, 
    .btn-secondary:hover,
    .btn-secondary:active,
    .btn-secondary:focus {
      background: linear-gradient(orange, orangered);
      border-color: orangered;
    }
} 

@media (max-width:760px) {
  .u-profile--icon-wrapper  {
   margin-top: -42px;
  }
}


.u-profile--notification-icon,
.u-profile--camera-icon,
.u-profile--speech-icon {
  background: transparent;
  width: 33%;
  border: none;
  height: 40px;
  margin-left:0 !important;
 
  border: none;
  box-shadow: none;

  .img-fluid {
    min-width: 20px;
    margin-top: 3px;
    min-height: 20px;
    display:block; 
    opacity: 0.9; 
  }
  svg.smaller2 { 
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-top: 10px;
    opacity:0.9;
    fill: #ffebd7;
  }
  svg.smaller {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-top: 5px;
    opacity:0.9;
    fill: #ffebd7;
  }
  svg {
    width:20px;
    min-width: 20px;
    min-height: 20px; 
    opacity:0.9;
    fill: #ffebd7;

    g {
      fill:inherit;

      path: {
        fill: inherit;
      }
    }
  }
}
.btn.u-profile--speech-icon.u-profile--speech-icon-1 svg {
  width: 12px;
  min-width: 12px;
  min-height: 22px;
}
.btn.u-profile--speech-icon.u-profile--speech-icon-2 svg {
  width: 16px;
  min-width: 16px;
  min-height: 17px;
}
 

.u-SimplestFeedBack-container {
  background: black;
  width: 100%;
  margin: 0;
  max-width: initial;
  background: #ffeddd;
}
.profilepg .u-SimplestFeedBack-container {
  margin-top: 70px;
}
.profilepg .u-SimplestFeedBack-form {
  margin-left: auto;
  margin-right: auto;
}
.profilepg .u-SimplestFeedBack-form .custom-select,
.profilepg .u-SimplestFeedBack-form textarea,
#SimplestFeedBack__select,
.profilepg .u-SimplestFeedBack-form input {
  background:white;
}
 
.profile-bet .u-SimplestFeedBack-container,
.profile-bet .u-SimplestFeedBack-form,
.profilebet .u-SimplestFeedBack-container,
.profilebet .u-SimplestFeedBack-form {
  background: #1e1d22;
} 
 .payment-form--small-print {
  padding:0;
  margin: 0;
 }

 .personality-test-next-prev-btn-wrap .btn {
  
  color: white;
  font-weight: bold;
  background: linear-gradient(37deg,#f68640, #ff5602);
 }
 .personality-test-part .personality_see_and_save_btn,
 .personality-test-part .btn[type=submit] {
  background: darkgoldenrod;
  background: linear-gradient(137deg,#f68640, #ff5602);
  color: white;
  font-weight: bold;
}

.personality__negative-test-result,
.personality__postive-test-result {
  background: #81C784;
}
.personality__postive-test-result h5,
.personality__negative-test-result h5,
.personality__postive-test-result h4,
.personality__negative-test-result h4,
.personality__negative-test-result p,
.personality__postive-test-result p {
color: black;
} 
.referredby-color {
  color: #ffeddd;
  text-align: center;
  display:block;

}
.width-80pct {
  width: 80% !important;
}
.width-90pct {
  width: 90% !important;
}
.width-100pct {
  width: 100% !important;
}