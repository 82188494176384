.share-modal {
  .modal-content {
    border-radius: 24px;
    .modal-body {
      padding: 20px;
      h5 {
        color: #000;
        font-size: 20px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 15px;
      }

      .share-buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        text-align: center;

        a {
          display: inline-block;
          text-decoration: none;
          color: #000;
          font-weight: 500;
          max-width: 80px;
          width: 100%;
          img {
            width: 60px;
          }

          p {
            margin-top: 15px;
          }
        }
      }

      .link-copy-wrapper {
        border-radius: 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: #fff;
        padding: 10px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          padding: 10px 5px;
          max-width: 240px;
          width: 100%;
          border: none;
        }

        button {
          border-radius: 24px;
          background: #7a7a7a;
          border: none;
          padding: 10px 20px;
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
}
