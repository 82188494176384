.personalityt-test {
  &__tab {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    border-radius: 12px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.5s ease;
    color: black;
    user-select: none;
    background-color: white;
    &:hover {
      background-color: #87ceeb;
      color: black;
    }
    @media (max-width: 576.99px) {
      font-size: 12px;
    }
    @media (max-width: 406.99px) {
      font-size: 10px;
    }
    @media (max-width: 359.2px) {
      font-size: 8px;
    }
  }
  &__done-tab{
    background-color: #467689;
    color:#fff;
  }
  &__selected-tab {
    background-color: #87ceeb;
    color: black;

    @media (max-width: 576.99px) {
      font-size: 12px;
    }
    @media (max-width: 406.99px) {
      font-size: 10px;
    }
    @media (max-width: 359.2px) {
      font-size: 8px;
    }
  }
}

// .tab-content {
//   height: 400px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.personality-test-part {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px;
  &__input-field {
    width: 40% !important;
  }
}

@media only screen and (max-width: 576px) {
  .personality-test-heading {
    font-size: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .bg-site-sky-blue {
    padding: 0.3rem 0.5rem;
    font-size: 10px;
  }
}

.centered-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 40%;
}

.red-icon {
  color: red;
  cursor: pointer;
}
.resultDiv {
  background-color: #a5d6a7;
  border: none;
  border-radius: 0% !important;
  margin-left: 5px;
  &__btn {
    border-radius: 0% !important;
    margin-left: 5px;
  }
}
