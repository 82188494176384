.privacy-policy {
  .page-heading {
    font-size: 22px;
    font-weight: 700;
    color: #000000;
    // margin: 16px 0px;
  }
  &__sidebar {
    border-right: 1px solid #f1f3f4;
    ul {
      li {
        list-style: none;
        font-size: 16px;
        font-weight: 500;
        color: #4d4b4b;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0px 14px;
        cursor: pointer;
      }
    }
  }
  &__right {
    h1 {
      font-size: 35px;
      font-weight: 700;
      color: #000000;
      margin: 16px 0px;
    }
    h2 {
      font-size: 27px;
      font-weight: 700;
      color: #000000;
      margin: 12px 0px;
      margin-left: 5px;
    }
    h3 {
      font-size: 21px;
      font-weight: 700;
      color: #000000;
      margin: 8px 0px;
      margin-left: 8px;
    }
    h4 {
      font-size: 18px;
      font-weight: 700;
      color: #000000;
      margin: 12px 0px;
      margin-left: 8px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-left: 8px;
      line-height: 2;
    }
    ul {
      li {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        margin-left: 8px;
        line-height: 2;
        // a {
        //   color: black;
        //   text-decoration: underline;
        // }
      }
    }
    .number-list {
      li {
        list-style-type: decimal !important;
        color: #6464e5;
        a {
          color: #6464e5;
          text-decoration: underline;
        }
      }
    }
  }
  .active {
    background-color: #f1f3f4 !important;
    color: #000000 !important;
  }
}
