/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

$theme-colors: (
  "site-primary": #0d2b60,
  "site-primary-lite": #2e73ec,

  "site-muted": #9899b3,
  "site-sky-blue": #87ceeb,

  "site-lite": #f8f7fc,
  "site-secondary": #eaeaf0,
  "site-gray": #e8f0ff,
  "site-lite-gray": #f7f8fa,
  "site-sky-lite": #d9e4f8,
  "site-main": #f7f8fa,
  "site-gray-500": #666885,
  "site-gray-600": #474967,
  "site-gray-900": #101128,
  "site-white": #ffffff,
  "site-red": #e74e69,
  "site-green": #2b9943,
  "site-purple": #a020f0,
  "site-twitter": #00acee,
);
.cursor-pointer:hover {
  cursor: pointer !important;
}
@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";
@import "login";
@import "profile";

@import "PersonalityTest";
@import "see-match";
@import "./PrivacyPolicy.scss";
