footer {
  position: relative;
  padding-top: 80px;
  background: linear-gradient(
    180deg,
    rgba(252, 163, 77, 0.2) 0%,
    rgba(252, 163, 77, 0.2) 100%
  );

  @media (max-width: 576px) {
    background-image: url("../../assets/images/footer-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 780px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.35);
      z-index: 1;
    }
  }

  @media (max-width: 991px) {
    padding-top: 0;
  }

  .mobile-spacing {
    @media (max-width: 576px) {
      margin-bottom: 50px;
    }

    .img-wrapper {
      @media (max-width: 576px) {
        display: none;
      }

      @media (max-width: 991px) {
        margin-top: -90px;
      }
    }

    h4 {
      z-index: 2;
      @media (max-width: 576px) {
        color: #fff;
      }

      @media (max-width: 991px) {
        margin-top: 50px;
      }
    }
  }

  .m-color {
    @media (max-width: 576px) {
      color: #fff;
      z-index: 10;
    }
  }
}

.m-z-index{
  z-index: 10;
}
