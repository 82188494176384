.nav-tabs .nav-link {
	border: 0 !important;
}
.pointer {
	cursor: pointer;
}


.profile_summary_table {
	width: 100%;
	background:transparent; 
  position:relative;
  font-family: 'DM Sans', Helvetica;
}
 
.profile {


	&__personality {
		width: 100px;
		height: 100px;
		border-radius: 50% !important;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: rgba(0, 0, 0, 0.72) 0px 1px 4px;
 

		&--text {
			font-size: 16px;
			color: black;
			font-family: sans-serif;

			text-align: center;
		}
		&--label {
			font-size: 16px;
			font-weight: 500;
			margin-top: 10px;
			color: black;
			font-family: sans-serif;
		}
	}

	.nav-link.active {
		background: #004366 !important;
		color: white;
		border: 2px solid #004366 !important;
		margin-top: -1px;
		margin-left: -1px;
	}

	&__detail {
		display: flex;
		font-size: 16px;
		font-weight: 500;

		&--question {
			width: 50%;
			text-align: right;
			font-weight:bold;
			font-family: 'DM Sans', Helvetica;
		}
		&--answer {
			padding-left: 8px;
			color: black;
			font-family: 'DM Sans', Helvetica;
			font-weight:bold;
		}

		@media (max-width: 576.99px) {
			&--question {
				width: 100px !important;
			}
			font-size: 12px;
		}
	}
	&__stat {
		@media (max-width: 576.99px) {
			font-size: 12px;
		}
		@media (max-width: 360.99px) {
			font-size: 10px;
		}
	}
}
.cursor {
	cursor: pointer;
}
