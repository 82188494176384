.overlay, 
.overlay-open,
.modal .modal-dialog.overlay-slide-up.fade {
    position: fixed;
    min-width: 200px;
    height: 400px;
    bottom: 0;
    background: tan;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1000;
    max-width: 90%;
    border-radius: 32px 32px 0 0;
    transform:translate3d(0,100vh,0);
    transition:transform 700ms ease-out 200s;
}
.modal .modal-dialog.overlay-slide-up.fade {
    opacity:1;
}
.overlay .overlay-open,
.overlay-open,
.modal .modal-dialog.overlay-slide-up.show.fade {
    transform:translate3d(0,0,0);
}
.letsUpgradeYou-Modal.modal-dialog {
    border-radius: 32px 32px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    background: linear-gradient(5deg, #ffe3a7, #ffefce);
}

.letsUpgradeYou-Modal .modal-content {
    background: transparent;
    background: linear-gradient(0deg, #FF6F00,#FFA000, #FFCA28,#FFD54F, #FFE082, #FFE082 );
    background: linear-gradient(176deg, #FF6F00,#FFA000);

    background: linear-gradient(176deg, #FF6F00,#FFF3E0);
    height: auto;
}
.modal-open .modal { 
    transform:translate3d(0,100vh,0);
    transition:transform 200ms ease-out 0s;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: unset;
}

.modal-open .modal.show {
    max-width: 390px;
    transform:translate3d(0,0,0);
    transition:transform 200ms ease-out 0s;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: unset;
    height: 100vh;
}
.variant-border-radius {
    border-radius: 16px 2px !important;
    background-color:#ff5602 !important;
    color: white !important;
    width:120px;
    font-weight:bold;
}
.btn.btn--close-modal {
    left: initial;
    right: 2vw;
    background: #FF5722;
    background: linear-gradient(45deg, #ff980000, #ef6c0000);
    min-width: 43px;
    min-height: 40px;
    box-shadow:none;
    top: 2px;
    border:none;
    position:absolute;
    z-index:1000;
    display:block;
  }
  .btn.btn--close-modal:hover,
  .btn.btn--close-modal:active,
  .btn.btn--close-modal:focus {
    background: linear-gradient(45deg, #ff9800, #ef6c00);
    
  }
.letsUpgradeYou__video-frame{
    border: 8px solid #F57C00;
    border-radius: 18px;
    background: #FB8C00;
    overflow: hidden;
}



  .btn.btn--close-modal svg {
    fill: white;
  }