.u-js-animation-parent .u-animated-icon {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 170px;
    margin-top: 20px;
    visibility: hidden;
    transition: visibility 200ms ease-in 0s;
}

.u-js-animation-parent.is-closed .u-animated-icon {
    visibility: visible;
    animation: bounceIcon 2s ease-in 0s 1 forwards;
}

 
@-webkit-keyframes bounceIcon {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
  }

@keyframes bounceIcon { 
    0%   { opacity:0; transform: scale(1,1)      translateY(0); }
    10%  { opacity:0;transform: scale(1.1,.9)   translateY(0); }
    30%  { opacity:0.1; transform: scale(.9,1.1)   translateY(-100px); }
    50%  { opacity:1; transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
  }

 @-webkit-keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);} 
 } 
 
 @keyframes bounce { 
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
 }

@-webkit-keyframes bounce2 { 
    0%, 20%, 50%, 80% {-webkit-transform: translateY(0);} 
    40% {-webkit-transform: translateY(-30px);} 
    60% {-webkit-transform: translateY(-15px);}
    100% {-webkit-transform: translateY(65px);}
 } 
 
@keyframes bounce2 { 
  0%, 20%, 50%, 80% {transform: translateY(0);} 
  40% {transform: translateY(-30px);} 
  60% {transform: translateY(-15px);} 
  100% {transform:  translateY(65px);}
}
