.interests {
  max-width: 375px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 20px;
  margin: 0 auto;

  h4 {
    margin-top: 75px;
    color: #000002;
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    line-height: 150%;

    @media (max-width: 576px) {
      margin-top: 30px;
      font-size: 24px;
    }
  }

  h6 {
    color: #302e31;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 800;
    text-align: center;
    line-height: 150%;
  }

  .search-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 25px 0;
    .search-field {
      background-color: #fff;
      border-radius: 12px;
      padding: 8px 20px;
      input {
        border: none;
        padding-left: 10px;
        width: 80%;
      }
    }

    button {
      background-color: #000;
      border: none;
      width: 38px;
      height: 38px;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  .intererest-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.interest-button {
  color: #ff5603;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  background-color: #fff;
  border: 1px solid #e8e6ea;
  border-radius: 100px;
  padding: 10px 12px;
  text-align: center;
  transition: linear 0.3s all;
  cursor: pointer;
  margin-bottom: 0 !important;

  &.selected {
    background-color: #ff5603;
    color: #fff;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;
    background-color: #ffffff33;
    color: black;
    border-color: #00000045;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }
}

// Gender Component CSS

.gender {
  height: 750px;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // align-items: center;
  gap: 20px;
  will-change: margin;
  transition: margin-top 200ms ease-in 0s;
  margin-top: 100px;

  h3 {
    color: #f0f0f0;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 30px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    .gender-button {
      border-radius: 12px;
      border: 1px solid #e8e6ea;
      background: #fff;
      padding: 15px 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.active {
        background-color: #ff5603;
        color: #fff;
      }

      input {
        border: none;
      }

      input[type="radio"] {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
      }
    }
  }
}

.interested-gender {
  height: 750px;
}

.range-wrapper {
  background-color: #ff5603;
  border-radius: 16px;
  padding: 25px 20px;
}

// Types CSS

.other-type-wrapper {
  // background-color: #fff;
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-top: 15px;

  .other-type {
    background-color: #fff;
    border: none;
    border-radius: 16px;
    padding: 12px 14px;
    color: #ff5603;
    margin-top: 0 !important;
    width: 100%;

    &::placeholder {
      color: #ff5603;
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 400;
      background-image: url("../../assets/images/Edit.svg");
      background-size: auto;
      background-repeat: no-repeat;
      background-position: right;
    }
  }

  button{
    border: none;
    border-radius: 16px;
    padding: 12px 14px;
    background-color: #ff5603;
    color: #fff;
  }
}

// Relationship CSS

.relationship-item-wrapper {
  margin-bottom: 30px;

  .relationship-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    min-height: 130px;
    height: 100%;
    padding: 10px;
    border-radius: 16px;
    background: #fff;
    cursor: pointer;
    transition: 10ms linear  all;

    h6 {
      color: #ff5603;
      font-size: 14px;
      font-weight: 400;
      transition: linear 0.3s all;
    }

    &.active,
    &:hover {
      background-color: #ff5603;

      h6 {
        color: #fff;
      }
    }
  }
}


.optional-input.is-selected {
  background-color:#ff5603;

  label {
    background-color:#ff5603;
    color:white;
    font-weight:bold;
    span {
      background-color:#ff5603;
      color:white;
      font-weight:bold;   
    }
  }
  input {
    background-color:#ff5603;
    color:white;
    font-weight:bold;    
  }
}

.optional-input {
  padding: 14px;
  background-color: #fff;
  border-radius: 16px;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ff5603;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: #ff5603;
    &::placeholder {
      color: #ff5603;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

// Matching Location Wrapper

.matching-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 280px;

  p {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    width: 90%;
    margin: 0 auto;
  }

  input {
    background-color: #ff5603;
    padding: 12px 20px;
    border: none;
    border-radius: 12px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 12px;
    max-width: 220px;
    width: 100%;
    height: 50px;

    &::placeholder {
      color: #fff;
    }
  }

  .radius-range-wrapper {
    background-color: #ff5603;
    padding: 10px 20px;
    border-radius: 16px;
    text-align: left;
    height: 70px;

    span {
      color: #fff;
      font-family: Montserrat;
      font-size: 10px;
      font-weight: 500;
    }
  }
}

// Dream Place CSS

.dream-place {
  p {
    color: #fff;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  input {
    width: 100%;
    margin-top: 15px;
  }
}

// Final step css

.profile-info {
  h5 {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 20px;
  }

  .profile-img-wrapper {
    margin-bottom: 0;

    .profile-img {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 22px;
      // overflow: hidden;
      margin: 0 auto;
      width: 50%;
      height: auto;
    }

    .upload-btn {
      position: absolute;
      bottom: -15px;
      right: -5px;
      height: 35px;
      width: 35px;
      background-color: #ff9933;
      border: 2px solid #fff;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    padding: 14px 20px;
    background-color: #fff;
    border: 1.5px solid #ff9228;
    border-radius: 12px;
    margin-bottom: 20px;

    input {
      width: 100%;
      background-color: #fff;
      border: none;
      color: #22172a;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
    }

    img {
      position: absolute;
      bottom: 0;
      right: -11px;
    }

    .toolTip {
      position: absolute;
      top: -15px;
      right: -10px;
      opacity: 0;
      visibility: hidden;
      background-color: #ff5603;
      padding: 5px 10px;
      border-radius: 8px;
      transition: linear 0.3s all;

      p {
        font-size: 10px !important;
        color: #fff;
      }
    }

    &:hover {
      .toolTip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .suggestedCountryWrapper {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    .suggestedCountry {
      color: #ff5603;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      background-color: #fff;
      border: 1px solid #e8e6ea;
      border-radius: 100px;
      padding: 10px 12px;
      text-align: center;
      transition: linear 0.3s all;
      cursor: pointer;
      margin-bottom: 0 !important;

      &:hover {
        background-color: #ff5603;
        color: #fff;
      }
    }
  }
}

.bottom-drawer {
  position: absolute;
  width: 100%;
  height: 340px;
  background-color: #fff;
  bottom: -340px;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 20px 30px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  cursor: pointer;
  transition: 0.5s;

  h6 {
    color: #ff5603;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  p {
    color: #ff5603;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .accept-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      color: #ff5603;
      font-size: 14px;
      font-weight: 700;
    }

    input {
      accent-color: #ff5603;
      height: 18px;
      width: 18px;
    }
  }

  &.active {
    bottom: 0px;
    transition: 0.5s;
  }
}

.over-lay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
}

// Rc Slider css
.rc-slider-rail {
  background-color: #ccc4c2 !important;
  height: 1.5px !important;
}

.rc-slider-track {
  background-color: #fff !important;
  height: 1px !important;
}

.rc-slider-handle {
  margin-top: -6px !important;
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

.rc-slider-tooltip-inner {
  background-color: transparent !important;
  box-shadow: none !important;
  font-size: 9px !important;
  padding: 0 !important;
}
