/* Payment Form CSS */
.payment-form {
    top: 0;
    position:relative;
    display:block;
    background: linear-gradient(115deg, #FFA726, #FF7043);
    background: linear-gradient(201deg, #d9afc4 0%, #7A5A75 18%, #493d57 40%);
    background: radial-gradient( #d9afc4, #7a5a74, #493d57);
    background: radial-gradient(circle at 50% 15%, #ffa726 6%, #ff7043 9%, #d9afc4 9%, #7A5A75 15%, #493d57 35%);
    background: linear-gradient(115deg, #FFA726, #FF7043);
} 
.payment-form > .row {
  max-width: 600px;
  margin-left: auto;
  margin-right:auto;
}
.payment-form__variant-list {
  flex-wrap: wrap;
}

.payment-form__variant-list .product-thumbnail__btn {

  overflow: hidden;
  background:transparent;
  flex: 1 1 calc((237px - 10px) / 3); /* 237px container width minus 10px gap divided by 3 */
  text-align: center;
  margin: 5px; /* Adjust margin as needed */
  padding: 10px; /* Adjust padding as needed */

} 
.payment-form__variant-list .product-thumbnail__btn.selected {
  background:linear-gradient(115deg, #FFA726, #FF7043);
}
.payment-form__variant-list .product-thumbnail {
  
  width:40px
}

.you-saved-txt {
  color: green;
  margin: 0 0 0 auto;
  position:relative;
  display:inline-block;
  font-size: smaller;
}
.payment-form .text-style-1,
.payment.-form .text-style-2 {
  display: inline-block;
}
 
.payment-form .text-style-2 {
  color: #f75911;
  text-shadow: 0px 0px 0 #f75911;

}
.payment-form .text-style-3 {
  color: #fb8c00; 
}

.payment-form .text-style-4-label,
.payment-form .text-style-4 {
  font-weight: bold;
    font-size: 12px;
    border-radius: 8px;
    padding: 2px 4px;
    display: inline-block;
    background: #766787;
    border: none;
    box-shadow: none;
    background: linear-gradient(115deg, #ff9d0d, #ff9731);
    text-shadow: 1px 1px 1px #ffaf27;
}
.payment-form .text-style-4-label {
  text-shadow:none;
}
.payment-form .text-style-4-label {
  background: #886781;
}
.payment-form-header.line-height-3 {
 line-height: 3;
}
.payment-form  .logo-left {
  width: 70px;
  display: inline-block;
}
.optional-items {
  text-align:left;
  max-width: 49%;
  margin: 0;
}

.u-tint-1,
.u-tint-2 {
  font-size: 16px;
}
@media (min-width:805px) {
  .u-responsive-block{
    display:block;
  }
}


/*
.u-tint-1{
  color: #f75911 !important;
  text-shadow: 0px 0px 0 #f75911 !important;
}
.u-tint-2 {
  color:#fb8c00 !important;
}
*/
.optional-items--tinted {
  border: none;
  border-radius: 20px;
  background: #FFE0B2;
  background: linear-gradient(320deg, #f759115e, #fb8c018c);
  padding: 2px;
  box-sizing: border-box;
  background: linear-gradient(110deg, #ff9f438f, #ffa529a6, #ffa529b3 70%, #ffa529c2, #ff9f438f);
}
.optional-items__label-wrapper {
  min-height: 50px;
  height: 50px;
  position: relative;
  display: block;
}
.optional-items__sel-list {
  width: 60px;

}
.optional-items .media {
  width:90%;
  height: auto;
  margin: 2px auto 8px auto;
  display: block;
  border-radius:20px;
}
.payment-form #signin {
  background: transparent;
  margin-top: 80px;
 
}
.payment-form #signin:after {
  background: transparent;
}

.payment-form #signin .fw-700 {
  color: white;
} 
.payment-form .optional-items label {
    
    height: 40px;
    position: relative;
    display: block;
}
.payment-form  label > svg {
  margin-right:8px;
}
.payment-form .optional-items label input {
    margin: 0 8px;
}
.payment-form-wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 30px auto;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    border-radius: 12px;
  }

  .payment-form__line-item{
    margin-left: 24px;
    font-weight:400;
    padding-bottom: 0;
    margin-top:8px;
  } 
  .referreName__highlight {
    font-size:17px;
    font-weight:bold;
  }
  .payment-form__line-item p {
    font-weight:600;
    padding-left: 0;
  }
  .payment-form__line-item.total-amount {
    margin-top: 16px;
  }
  .payment-form__line-item.total-amount p {
    font-size:16px;
    font-weight: 700;
  }
  .vid-media {
    width: 100%;

  }

  .payment-form-header {
    font-size: 24px;
    font-weight: 700;
    width: 100%;
    margin-bottom: 20px;
    color: var(--gogOrange1);
  }

  .product-img {
    border-radius: 16px;
  }
  
  .payment-form-label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .payment-form-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .payment-form-button {
    background: var(--gogOrange1);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 0px 5.062880992889404px 18px 0px #892d00;
  }
  
  .payment-form-button:hover,
  .payment-form-button:active,
  .payment-form-button:focus {
    background: #e04b00;
  }
  
  .payment-form-footer {
    font-size: 12px;
    color: #777;
    text-align: center;
    margin-top: 20px;
  }
  .line-through {
    text-decoration: line-through !important;
  }
  
.successMsgNotification,
.errMsgNotification {
    display:none;
    position:relative; 
    z-index: 0;
    height: 0;
    transition: height 200ms ease-in 0s;
    will-change:height;

    
    width: 100%;
   
    border-radius: 8px;
    color: white;
    font-weight: 700;
}
.successMsgNotification {
    background: #2e7d32;
   
}
.errMsgNotification {
  background: darkred; 

}
.successMsgNotification.is-visible,
.errMsgNotification.is-visible {
    display:block; 
    z-index: 10;
    height: auto;
}