.login {
  &__buttonShadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
  }
  &__twitterbutton {
    background-color: white;
  }
  &__img {
    height: 20px;
    width: 20px;
  }
}
.auth_icon {
  margin-top: -35px !important;
}
