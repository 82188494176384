/* CustomSelect.css */

.custom-type-select {
  position: relative;
  width: 100%;
  // background-color: #fff;
}

.select-wrapper {
  // position: absolute;
  transition: 0.5s ease-out;
  border-radius: 16px;
  height: 145px;
  width: 100%;
  // top: 0;
  // left: auto;
  border-radius: 16px;
  overflow: hidden;

  &.active {
    // top: 100%;
    height: 145px;
  }
}

.select-box {
  cursor: pointer;
  padding: 10px 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 16px;

  &.radius-none {
    border-radius: 0;
  }
}

.selected-option {
  margin-right: 10px;
  color: #ff5603;
  font-size: 16px;
  font-weight: 700;
}

.arrow {
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 114px;
  overflow-y: auto;
  overflow-y: scroll;
  // border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
  text-align: left;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.option {
  display: block;
  margin: 0 !important;
  padding: 5px 20px;
  cursor: pointer;
  color: #ff5603;
  font-size: 16px;
  font-weight: 400;
  transition: none;

  &.active{
    background-color: #ff5603;
    color: #fff;
  }

  &:hover{
    background-color: #ff5603;
    color: #fff;
  }
}

.option input[type=checkbox] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}
