.whoIsInside.slider-wrapper .left-side {
    display: flex;
    flex-direction: column;
  }
  
  .whoIsInside.slider-wrapper .img-fluid {
    margin-top: auto;
  }
  
.whoIsInside.slider-wrapper .onboarding-slider .onboarding-slider-item .left-side {
    margin-bottom: 200px;
    padding-left: 10%;
    margin-top: auto;
}
  @media (max-width:768px) {
    .whoIsInside.slider-wrapper .onboarding-slider{
        display: flex !important;
    }
}

  .whoIsInside .header_msg { 
  position: absolute;
    margin: 0 auto;
    top: 40px;
    text-align: center;
    width: 100%;
    font-weight: bold;
    text-shadow: 1px 1px 1px white;
    color: wheat;
} 