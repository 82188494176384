.signin {
  position: relative;

  .back-btn {
    position: absolute;
    top: 25px;
    left: 100px;
    border: none;
    border-radius: 11px;
    background: #fca34d;
    width: 55px;
    height: 55px;
    z-index: 1;
    background: #0000003b;

    @media (max-width: 576px) {
      top: 20px;
      left: 20px;
    }
  }
}
