.hero-slider-item {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 935px;

  @media (max-width: 576px) {
    min-height: 600px;
  }
}
