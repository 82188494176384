.navbar .collapsing .navbar-toggler {
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i {
  margin-right: 4px;
}
.bottomRoutes {
  position: absolute;
  bottom: 0 !important;

  @media screen and (max-width: 576px) {
    position: relative;
  }
}
