.Partners.container,
.Partners {
    background: #fbdee5;
    width: 100%;
    position: relative;
    margin: 0;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
}
.Partners__main-header {
    width: 100%;
    display:block;
    max-width:100%;

}
.Partners p,
.Partners li,
.Partners h3,
.Partners span,
.Partners .text-secondary {
    color: black!important;
    font-weight: 300;
}
.Partners__hr {
    background: #ee7c98;
    width: 90%; 
    margin: 4px auto;
    display: block;
}
.border-top-solid  {
    border: 3px solid #ee7c98;
    border-width: 6px 0 0 0;
}
.Partners__img2,
.Partners__img3,
.Partners__img1 {
    display: inline-block;
    position: relative;
    width: 40%;
    height: auto;
    margin: 4px;
}
.Partners__img2 {
    width:65%;
    margin:8px auto;
    display:block;
    position: relative;
}
.Partners__img3 {
    width:25%;
}
.copy-to-clipboard-wrap {
    background: #FFE0B2;
    border-radius: 8px;
    border: 1px solid #FFA726;
    padding: 4px 8px;
    box-shadow: 4px 3px 12px darkgoldenrod;
    border: none;
    margin: 16px 4px;
}
.copy-to-clipboard-wrap .btn {
    background: #FF9800;
    font-weight:bold;
}
.Payouts, 
.Payouts p, 
.Payouts td,
.Payouts span {
    font-weight:bold;
}
.Partner__captions {
   width: 90%;
   box-shadow: 1px 1px 2px #81490047;
   margin:24px auto;
}
.header-welcome {
    font-size:3vw;
}

.fixed-table-layout {
    table-layout: fixed;
    height: auto;
}
.fixed-table-layout  th,
.fixed-table-layout  tr {
    height: auto;
}

.fixed-table-layout  th,
.fixed-table-layout  td {
    word-wrap: break-word;
    height: auto;
    text-wrap: wrap;
}
.fixed-table-layout  td {
    font-weight: 400;
}
.fixed-table-layout th:first-child,
.fixed-table-layout td:first-child {
    width: 60%;
   
}
  .fixed-table-layout th:last-child, 
  .fixed-table-layout td:last-child {
    width: 40%; 
}