.create-profile {
  min-height: 100vh;
  background-image: url("../../assets/images/create-profile-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #000;

  .create-profile--finalStep {
    background-size: 100% auto;
    background-position: 0 0;
  }

  .create-profile--interests .interests{
    min-height:initial;
  }

  .create-profile--interests .intererest-list {
    height: 300px;
    overflow-y: scroll;
  }

  .create-profile--ageRange .gender,
  .create-profile--gender .gender {
    height: 540px;
  }

  .create-profile--types .gender {
    gap: 9px;
    height: 560px;
    padding: 0 30px;
  }
  .create-profile--relationshipGoals .gender  {
    height: 542px;
    padding: 0 30px 0;
    gap: 0px;
  }
  .create-profile--matchingLocation .gender {
    height: 520px;
    padding: 0 30px;
    gap: 20px;
    .radius-range-wrapper span {
      font-weight:bold;
    }
    input {
      font-weight: bold;
      font-size: 13px;
    }

  }
  .create-profile--matchingLocation .matching-location {
    gap: 120px;
  }

  .diamond {
    text-align: center;
    margin: 20px 0;
  }

  form {
    position: relative;
    max-width: 575px;
    margin: 0 auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 70px;
    overflow: hidden;

    @media (max-width:576px) {
      margin-bottom: 0px;
    }

    .button-wrapper {
      position: relative;
      text-align: center;
      padding-bottom: 100px;
      padding-top: 30px;

      .next-btn {
        padding: 16px 30px;
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 100px;
        z-index: 1;

        &:disabled {
          cursor: not-allowed;
        }

        &.next-shadow {
          box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.48) inset;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }
      }
    }

    .back-btn {
      position: absolute;
      z-index: 1;
      top: 20px;
      left: 20px;
      border: none;
      background-color: transparent;
    }
  }

  .footer {
    position: relative;
    background-color: transparent !important;
    padding: 40px 0;
    @media (max-width:576px) {
      padding: 0px;
    }

    p,
    .footer-link {
      color: rgba(255, 255, 255, 0.4);
      text-decoration: none;
    }
  }
}

.rc-slider-tooltip-content {

  .rc-slider-tooltip-inner  {
    font-weight:bold;
  }

}

