
/* .u-avatar-circle */

.avatar-section {
  width: 100%;
  background: #ff5602;
  height: 150px;
  position: relative;
  padding: 0;
  display:block;
  border-radius: 0 0 32px 32px;
  margin: 0 auto 106px auto;
  max-width: 900px;
  left:0;
  right:0;
}


.u-avatar-circle:focus .circular-graph,
.u-avatar-circle:hover .circular-graph,  
.u-avatar-circle:active .circular-graph, 
.u-personalitytest--bar:focus .circular-graph,
.u-personalitytest--bar:hover .circular-graph,  
.u-personalitytest--bar:active .circular-graph {
    box-shadow: 3px 5px 14px #757575;
}

/**
 *  .circular-graph
 */ 
.u-avatar-circle .circular-graph,
.circular-graph {
    width: 60px;
    height: 60px;
    position: absolute;
    display: block;
    margin: -10px -7px 0 auto;
    z-index: 30;
    right: 0;
    top: 0;
    border-radius: 100%;
}

.u-avatar-circle .circular-graph:before,
.circular-graph:before {
    content: attr(percentcompleted);
    color: white;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
  transform: translate(1px, 64%);
    z-index: 100;
}

.u-avatar-circle .circle-svg,
.circle-svg {
    transform: rotate(-90deg);
    height: 60px;
   width: 60px;
    background: #ff5602;
    border: none;
    border-radius: 100%;
    overflow: hidden;
} 

.u-avatar-circle .circle,
.circle {
  fill: transparent;
  stroke: #22b332;
  stroke-width: 3px; 
  stroke-linecap: round;
  stroke-dasharray: 150.79px; /* 50 * pi = 157 */ /* 40% radius = 40% of 60 =  24;  60 * 2 * pi =  */
  stroke-dashoffset: 150.79;
  transition: stroke-dashoffset 3s ease-in-out 0.1s;
  filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.25));
}

.u-avatar-circle .circle-white,
.circle-white {
  stroke: white;
  stroke-width: 3.5px; 
  stroke-dashoffset: 0;
}

.circle-green {
  stroke: #22b332;
  stroke-dashoffset: 150.79;
  stroke-width: 3px; 
}

.circle-blue {
  stroke: #537def;
  stroke-dashoffset: 150.79;
  stroke-width: 3px; 
}
 
.u-avatar-circle-img {
position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: 100%;
    overflow: hidden;
}

.u-avatar-circle {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    background: rgb(83 125 238);
    padding: 0;
    line-height: 1;
    top: 6.1vh;
    border-radius: 100%;
    border: none;
    z-index: 1000;
}

.u-avatar-circle .circle-svg,
.u-avatar-circle .circular-graph { 
    width: 150px;
    height: 150px;
    margin: 0;
}

.u-avatar-circle .circular-graph:before {
    border: 1px solid #ffffff9c;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background: #24b232;
    left: initial;
    right: 17%;
    bottom: 8%;
    line-height: 3.1;
    font-size: 10px;
}

.u-avatar-circle .circle-svg {
   background: transparent;
  border: 1px solid transparent;
}

.u-avatar-circle .circle { 
    stroke-dashoffset: 400;
    stroke: #22b332;
    stroke-width: 15px;
    stroke-linecap: butt;
    stroke-dasharray: 471.24px;
    stroke-dashoffset: 140;
}

.u-avatar-circle .circle.circle-white {
	stroke-width: 16px;
	stroke-dasharray: 471.24px;
	stroke-dashoffset: 0;
	stroke: white;
 }

.circle-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transform: rotate(90deg);
	transform-origin: center;
}