/* .u-personalitytest--bar */

.u-personalitytest__button-container {
width: 300px;
position: relative;
margin: 0 auto;
display: block;
width: 71%;
}

 .u-personalitytest--bar {
    position: relative;
    display: block;
    left: 0;
    height: 38px;
    font-family:'DM Sans', Helvetica;
    width: 244px; 
    background: #FF5603;
    border-radius: 8px;

    color:white;
    font-variant: all-small-caps;
    font-weight: bold;
    font-size: 16px;
    margin: 8px 0px 0px 0px;
    background: linear-gradient(37deg,#f68640, #ff5602);
    width: 100%;
    transition: width 200ms ease-in 0s;
    will-change: width; 
    cursor:pointer;
  }

  .u-js-animation-parent.is-closed .u-personalitytest--bar { 
    width: 132px;
  }
  
  .u-animated-icon-character,
  .u-js-animation-parent .u-animated-icon-character {
    position: absolute;
    display: block;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    width: 90px;
    height: 90px;
    margin-left: 204px;
    margin-top: -30px;
    z-index: 10;
    transition:top 1s ease-in 0s;
   }
  
  
  .u-js-animation-parent.is-closed  .u-animated-icon-character {
    animation: bounce2 2s ease-in 0s 1 forwards;
  }
  .u-js-animation-parent .u-animated-icon { 
    position:absolute;
    display:block; 
    width: 80px;
    height: 40px;
    margin-left: 54%;
    margin-top: -27px;
    visibility:hidden;
    transition:visibility 200ms ease-in 0s;
  }
  
  .u-js-animation-parent.is-closed .u-animated-icon {
    visibility: visible;
    animation: bounceIcon 2s ease-in 0s 1 forwards;
  }
   
  @-webkit-keyframes bounceIcon {
      0%   { transform: scale(1,1)      translateY(0); }
      10%  { transform: scale(1.1,.9)   translateY(0); }
      30%  { transform: scale(.9,1.1)   translateY(-100px); }
      50%  { transform: scale(1.05,.95) translateY(0); }
      57%  { transform: scale(1,1)      translateY(-7px); }
      64%  { transform: scale(1,1)      translateY(0); }
      100% { transform: scale(1,1)      translateY(0); }
    }
  
  @keyframes bounceIcon { 
      0%   { opacity:0; transform: scale(1,1)      translateY(0); }
      10%  { opacity:0;transform: scale(1.1,.9)   translateY(0); }
      30%  { opacity:0.1; transform: scale(.9,1.1)   translateY(-100px); }
      50%  { opacity:1; transform: scale(1.05,.95) translateY(0); }
      57%  { transform: scale(1,1)      translateY(-7px); }
      64%  { transform: scale(1,1)      translateY(0); }
      100% { transform: scale(1,1)      translateY(0); }
    }
  
   @-webkit-keyframes bounce { 
      0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);} 
      40% {-webkit-transform: translateY(-30px);} 
      60% {-webkit-transform: translateY(-15px);} 
   } 
   
   @keyframes bounce { 
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
      40% {transform: translateY(-30px);} 
      60% {transform: translateY(-15px);} 
   }
  
  @-webkit-keyframes bounce2 { 
      0%, 20%, 50%, 80% {-webkit-transform: translateY(0);} 
      40% {-webkit-transform: translateY(-30px);} 
      60% {-webkit-transform: translateY(-15px);}
      100% {-webkit-transform: translateY(65px);}
   } 
   
  @keyframes bounce2 { 
    0%, 20%, 50%, 80% {transform: translateY(0);} 
    40% {transform: translateY(-30px);} 
    60% {transform: translateY(-15px);} 
    100% {transform:  translateY(65px);}
  }
  
  .u-personalitytest--bar span {
    display: inline-block;
    position: relative;
    padding: 6px 0 6px 17px;
    overflow: hidden;
    height: 38px;
  }
  .u-personalitytest--bar:focus,
  .u-personalitytest--bar:hover,  
  .u-personalitytest--bar:active {
    box-shadow:  2px 2px 10px #ffffff;
   
  }  
   
  .u-personalitytest--bar:focus .circular-graph,
  .u-personalitytest--bar:hover .circular-graph,  
  .u-personalitytest--bar:active .circular-graph {
    box-shadow: 3px 5px 14px #757575;
  }
  
  
  
  /*
   the big avatar graph
  
   to change the dimensions follow these steps. 
  
   1. change the width and height here 
   2. change the  stroke-dasharray: 356.88px; 
     with the formula  40% of the radius  * 2 * pi 
   3. change the svg view box 0 0 width height 
  
  
  Example 
  
  1. 200px is the width and height 
  2. 100 * 0.4 * 2 * pi = 251.33
  3. view box is changed to 0 200 200 
  
  
  1. 150px is the width and height 
  2. 100 * 0.4 * 2 * pi = 251.33
  3. view box is changed to 0 200 200 
  
  
  uniquely black apartment complexes need to be designed differently to allow the sun to come in and to allow people a place to grow food. 
  
     */
  
  
  /**
   *  .circular-graph
   */  
  .circular-graph {
    width: 60px;
    height: 60px;
    position: absolute;
    display: block;
    margin: -10px -7px 0 auto;
    z-index: 30;
    right: 0;
    top: 0;
    border-radius: 100%;
  }
   
  .circular-graph:before {
    content: attr(percentCompleted); /* attr("percentCompleted"); */
    color: white;
    margin: 0 auto;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    transform: translate(1px, 64%);
    z-index: 100;
  }
   
  .circle-svg {
    transform: rotate(-90deg);
    height: 60px;
   width: 60px;
    background: #ff5602;
    border: none;
    border-radius: 100%;
    overflow: hidden;
    background: linear-gradient(20deg,#f68640, #ff5602);
  } 
   
  .circle {
    fill: transparent;
    stroke: #22b332;
    stroke-width: 3px; 
    stroke-linecap: round;
    stroke-dasharray: 150.79px; /* 50 * pi = 157 */ /* 40% radius = 40% of 60 =  24;  60 * 2 * pi =  */
    stroke-dashoffset: 150.79;
    transition: stroke-dashoffset 1.5s ease-in-out 0s;
    filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.25));
  }
  
   
  .circle-white {
    stroke: white;
    stroke-width: 3.5px; 
    stroke-dashoffset: 0;
  }
  
  
  .circle-green {
    stroke: #22b332;
    stroke-dashoffset: 150.79;
    stroke-width: 3px; 
  }
  
  .circle-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotate(90deg);
    transform-origin: center;
  }
  
  
  /* end of space */
  
  
    /* screen - profile */
    .u-tbl--wrapper,
  .button-section {
    margin: 0 auto;
    position: relative;
    z-index: 10;
    background: #1e1d22;
    width: 75%;
   
    border-radius: 28px 28px 0 0;
    border: none;
    padding: 0;
    padding-top: 16px;
  }
  @media (min-width:414px) {
    .u-tbl--wrapper,
    .button-section {
      min-width:348px;
    }
  }
  .button-section .name-description {
    margin-top: -41px;
}
.button-section .name-description p {
  height:44px;
  min-height:44px;
}
  .profile2 {
      align-items: baseline;
      background-color: #f1f1fa;
      border: 1px none;
      display: flex;
      flex-direction: column;
      height: auto;
      width: 375px;
      margin: 0 auto;
      padding-bottom: 48px;
  }
  
  .profile2 .overlap-group17 {
    height: 313px;
    margin-top: -3px;
    position: relative;
    width: 375px;
  }
  
  .profile2 .overlap-group14 {
    height: 192px;
    left: 0;
    position: absolute;
    top: 0;
    width: 375px;
  }
  
  .profile2 .options {
    display: flex;
    flex-direction: column;
    gap: 3px;
    height: 24px;
    left: 331px;
    position: absolute;
    top: 33px;
    width: 24px;
  }
  
  .profile2 .ellipse-37 {
    background-color: var(--white);
    border-radius: 2px;
    height: 4px;
    margin-left: 10px;
    margin-top: 3px;
    width: 4px;
  }
  
  .profile2 .ellipse-3 {
    background-color: var(--white);
    border-radius: 2px;
    height: 4px;
    margin-left: 10px;
    width: 4px;
  }
  
  .profile2 .rectangle-901 {
    background-color: var(--international-orange);
    border-radius: 0px 0px 20px 20px;
    height: 126px;
    left: 0;
    position: absolute;
    top: 0;
    width: 375px;
  }
  
  .profile2 .navigation-bar {
    display: flex;
    height: 88px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 375px;
  }
  
  .profile2 .left-action {
    flex: 1;
    height: 44px;
    margin-top: 44px;
  }
  
  .profile2 .overlap-group {
    height: 149px;
    left: 113px;
    position: absolute;
    top: 50px;
    width: 149px;
  }
  
  .profile2 .profile-header {
    background-image: url(../../assets/img/profileImgs/ellipse-67-1@2x.png);
    background-position: 50% 50%;
    background-size: cover;
    height: 132px;
    left: 8px;
    position: absolute;
    top: 5px;
    width: 131px;
  }
  
  .profile2 .ellipse-26 {
    height: 149px;
    left: 0;
    position: absolute;
    top: 0;
    width: 149px;
  }
  
  .profile2 .ellipse-27 {
    height: 142px;
    left: 34px;
    position: absolute;
    top: 0;
    width: 111px;
  }
  
  .profile2 .ellipse-76 {
    background-color: #22b231;
    border: 0.87px solid;
    border-color: var(--white);
    border-radius: 13.5px;
    box-shadow: 0px 3.48px 17.42px #0000001a;
    height: 27px;
    left: 98px;
    position: absolute;
    top: 113px;
    width: 27px;
  }
  
  .profile2 .number {
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxl);
    font-weight: 600;
    left: 102px;
    line-height: 13.9px;
    top: 120px;
  }
  
  .profile2 .text-1 {
    color: var(--white);
    font-family: var(--font-family-montserrat);
    font-size: 7px;
    font-weight: 600;
    left: 117px;
    letter-spacing: 0;
    line-height: 13.9px;
    position: absolute;
    top: 118px;
    white-space: nowrap;
  }
  
  .profile2 .hamburger-menu {
    height: 13px;
    left: 26px;
    position: absolute;
    top: 52px;
    width: 18px;
  }
  
  .profile2 .rectangle-242 {
    cursor: pointer;
    height: 14px;
    left: 337px;
    position: absolute;
    top: 48px;
    width: 8px;
  }
  
  .profile2 .overlap-group13 {
    height: 170px;
    left: 36px;
    position: absolute;
    top: 193px;
    width: 326px;
  }
  
  .profile2 .user-name {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    left: 92px;
    padding: 10px;
    position: absolute;
    top: 0;
    width: fit-content;
  }
  
  .profile2 .jael-lovelli {
    color: #000000bf;
    font-family: var(--font-family-montserrat);
    font-size: var(--font-size-xxxxxl);
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: -1.00px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .profile2 .description {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 23px;
    width: fit-content;
  }
  
  .profile2 .each-button-is-a-per {
    color: var(--black);
    font-family: var(--font-family-dm_sans);
    font-size: var(--font-size-xl);
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.00px;
    position: relative;
    width: 306.42px;
  }
  
  .profile2 .card-container {
    height: 142px;
    left: 10px;
    position: absolute;
    top: 79px;
    width: 158px;
  }
  
  .profile2 .card-pre-build-1 {
    height: 39px;
    top: 38px;
    width: 78px;
  }
  
  .profile2 .card-card-content-1 {
      height: 57px;
      left: 52px;
      width: 57px;
      top: 30px !important;
  }
  
  .profile2 .gog-experience_uhd_ {
      height: 54px;
      object-fit: cover;
      position: absolute;
      width: auto;
  }
  
  .profile2 .character-2 {
    width: 89px;
  }
  
  .profile2 .notification-bell {
    align-items: flex-start;
    display: flex;
    height: 59px;
    left: 44px;
    padding: 1px 9px;
    position: absolute;
    top: 139px;
    width: 65px;
  }
  
  
  .profile2 .overlap-group1 {
    height: 36px;
    position: relative;
    width: 32px;
  }
  
  .profile2 .icon-notifications {
    height: 36px;
    left: 0;
    position: absolute;
    top: 0;
    width: 31px;
  }
  
  .profile2 .ellipse-155 {
    background-color: var(--international-orange);
    border: 1px solid;
    border-color: var(--white);
    border-radius: 7px/7.02px;
    height: 14px;
    left: 18px;
    position: absolute;
    top: 7px;
    width: 14px;
  }
  
  .profile2 .number-1 {
    font-family: var(--font-family-inter);
    font-size: var(--font-size-m);
    font-weight: 400;
    left: 22px;
    line-height: normal;
    top: 7px;
    width: 8px;
  }
  
  .profile2 .overlap-group18 {
    height: 496px;
    margin-left: 23.84px;
    position: relative;
    width: 317px;
  }
  
  .profile2 .overlap-group15 {
    height: 455px;
    left: 0;
    position: absolute;
    top: 16px;
    width: 317px;
  }
  
  .profile2 .earn-10-coins {
    display: flex;
    flex-direction: column;
    height: 455px;
    left: 250px;
    position: absolute;
    top: 0;
    width: 67px;
  }
  
  .profile2 .price-1 {
    height: 13px;
  }
  
  .profile2 .price-2 {
    height: 11.81px;
    margin-top: 52.2px;
    white-space: nowrap;
  }
  
  .profile2 .price-3 {
    height: 13px;
    margin-top: 57.0px;
  }
  
  .profile2 .price-4 {
    height: 13px;
    margin-top: 49px;
  }
  
  .profile2 .price-5 {
    height: 13px;
    margin-top: 44px;
  }
  
  .profile2 .price-6 {
    height: 13px;
    margin-top: 55px;
  }
  
  .profile2 .price-7 {
    height: 13px;
    margin-top: 42px;
  }
  
  .profile2 .price-8 {
    height: 13px;
    margin-top: 53px;
  }
  
  .profile2 .card-container-1 {
    height: 46px;
    left: 0;
    position: absolute;
    top: 303px;
    width: 262px;
  }
  
  .profile2 .card-pre-build-2 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 4px;
    width: 213px;
  }
  
  .profile2 .price {
    margin-top: -0.85px;
    position: relative;
    width: 133.97px;
  }
  
  .profile2 .card-card-content-2 {
    align-items: center;
    display: flex;
    gap: 17.07px;
    left: 199px;
    width: 63px;
  }
  
  .profile2 .container-1 {
    left: -7px;
    top: -5px;
    transform: rotate(90.23deg);
  }
  
  .profile2 .group-197 {
    height: 46.08px;
    min-width: 46.08px;
    position: relative;
  }
  
  .profile2 .overlap-group-1 {
    background-image: url(../../assets/img/profileImgs/ellipse-77@2x.png);
    height: 53px;
    justify-content: flex-end;
    left: -3px;
    min-width: 53px;
    padding: 0.0px 3.4px;
    position: relative;
  }
  
  .profile2 .ellipse-78 {
    height: 46px;
    width: 45px;
  }
  
  .profile2 .percent {
    left: 15px;
    top: 16px;
  }
  
  .profile2 .card-container-2 {
    height: 46px;
    left: 5px;
    position: absolute;
    top: 118px;
    width: 274px;
  }
  
  .profile2 .card-pre-build {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    left: 0;
    padding: 12.8px 58.03px 12.8px 12.8px;
    position: absolute;
    top: 4px;
    width: 209px;
  }
  
  .profile2 .card-card-content {
    align-items: center;
    display: flex;
    gap: 17.07px;
    left: 191px;
    position: absolute;
    top: 0;
    width: 83px;
  }
  
  .profile2 .container {
    left: -6px;
    top: -5px;
    transform: rotate(90.23deg);
  }
  
  .profile2 .overlap-group-2 {
    background-image: url(../../assets/img/profileImgs/ellipse-77-3@2x.png);
    height: 53px;
    left: -3px;
    min-width: 53px;
    padding: 0 3.4px;
    position: relative;
  }
  
  .profile2 .ellipse-78-1 {
    height: 46px;
    width: 46px;
  }
  
  .profile2 .percent-1 {
    left: 11px;
    top: 16px;
  }
  
  .profile2 .card-container-3 {
    height: 46px;
    left: 5px;
    position: absolute;
    top: 48px;
    width: 274px;
  }
  
  .profile2 .container-2 {
    left: -6px;
    top: -4px;
    transform: rotate(90.23deg);
  }
  
  .profile2 .overlap-group-3 {
    background-image: url(../../assets/img/profileImgs/ellipse-77-3@2x.png);
    height: 53px;
    justify-content: flex-end;
    left: -3px;
    min-width: 53px;
    padding: 0.0px 8.3px;
    position: relative;
  }
  
  .profile2 .ellipse-78-2 {
    height: 10px;
    width: 18px;
  }
  
  .profile2 .overlap-group4 {
    height: 57px;
    left: 5px;
    position: absolute;
    top: 439px;
    width: 160px;
  }
  
  .profile2 .container-3 {
    left: 103px;
    top: 0;
    transform: rotate(90.23deg);
  }
  
  .profile2 .card-pre-build-3 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 9px;
    width: 135px;
  }
  
  .profile2 .price-9 {
    margin-right: -69.80px;
    margin-top: -0.85px;
    position: relative;
    width: 133.97px;
  }
  
  .profile2 .dislike {
    background-color: var(--white);
    border-radius: 23.21px;
    box-shadow: 0px 20px 50px #00000012;
    height: 46px;
    left: 109px;
    top: 5px;
    width: 46px;
  }
  
  .profile2 .freedom-zone-diamond-1 {
    height: 44px;
    left: 114px;
    object-fit: cover;
    position: absolute;
    top: 7px;
    width: 40px;
  }
  
  .profile2 .overlap-group5 {
    height: 57px;
    left: 5px;
    position: absolute;
    top: 372px;
    width: 158px;
  }
  
  .profile2 .dislike-1 {
    background-color: var(--international-orange);
    border-radius: 28.47px;
    box-shadow: 0px 17.07px 42.67px #00000012;
    height: 57px;
    left: 101px;
    top: 0;
    width: 57px;
  }
  
  .profile2 .card-pre-build-4 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 9px;
    width: 117px;
  }
  
  .profile2 .price-10 {
    margin-right: -87.80px;
    margin-top: -0.85px;
    position: relative;
    width: 133.97px;
  }
  
  .profile2 .dislike-2 {
    background-color: var(--white);
    border-radius: 23.21px;
    box-shadow: 0px 20px 50px #00000012;
    height: 46px;
    left: 107px;
    top: 5px;
    width: 46px;
  }
  
  .profile2 .jewelry-item-green-1 {
    height: 48px;
    left: 108px;
    object-fit: cover;
    position: absolute;
    top: 6px;
    width: 44px;
  }
  
  .profile2 .btn-upgrade-you {
    align-items: flex-start;
    box-shadow: 0px 4px 4px #00000029;
    display: flex;
    height: 46px;
    left: 3px;
    min-width: 240px;
    position: absolute;
    top: 257px;
  }
  
  .profile2 .card-container-4 {
    height: 47px;
    margin-top: -0.09px;
    position: relative;
    width: 240px;
  }
  
  .profile2 .card-pre-build-5 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 4px;
    width: 238px;
  }
  
  .profile2 .price-11 {
    height: 13.09px;
    margin-top: -0.85px;
    position: relative;
    width: 92.04px;
  }
  
  .profile2 .card-card-content-3 {
    align-items: center;
    display: flex;
    left: 194px;
    transform: rotate(90.38deg);
    width: 46px;
  }
  
  .profile2 .container-4 {
    left: -5px;
    top: -5px;
  }
  
  .profile2 .percent-2 {
    left: 14px;
    top: 17px;
    transform: rotate(-90.23deg);
  }
  
  .profile2 .group-197-1 {
    gap: 12797.8px;
    transform: rotate(-90.23deg);
  }
  
  .profile2 .ellipse-77 {
    height: 52.9px;
    margin-left: -6.8px;
    margin-top: -3.4px;
    transform: rotate(90.23deg);
    width: 52.91px;
  }
  
  .profile2 .ellipse-78-3 {
    height: 46.08px;
    margin-top: 4574.8px;
    transform: rotate(90.23deg);
    width: 46.08px;
  }
  
  .profile2 .card-container-5 {
    height: 46px;
    left: 5px;
    position: absolute;
    top: 196px;
    width: 240px;
  }
  
  .profile2 .card-pre-build-6 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 4px;
    width: 212px;
  }
  
  .profile2 .price-12 {
    margin-top: -0.85px;
    position: relative;
    width: 46.08px;
  }
  
  .profile2 .card-card-content-4 {
    align-items: center;
    display: flex;
    gap: 17.07px;
    left: 192px;
    width: 49px;
  }
  
  .profile2 .ellipse-77-1 {
    height: 52.91px;
    margin-left: -3.4px;
    margin-top: 0;
    width: 52.91px;
  }
  
  .profile2 .percent-3 {
    left: 14px;
    top: 16px;
  }
  
  .profile2 .card-container-6 {
    height: 46px;
    left: 5px;
    position: absolute;
    top: 0;
    width: 245px;
  }
  
  .profile2 .card-pre-build-7 {
    align-items: flex-start;
    background-color: var(--international-orange);
    border-radius: 8.53px;
    display: flex;
    flex-direction: column;
    gap: 12.8px;
    padding: 12.8px 58.03px 12.8px 12.8px;
    top: 4px;
    width: 199px;
  }
  
  .profile2 .price-13 {
    margin-right: -5.96px;
    margin-top: -0.85px;
    position: relative;
    width: 133.97px;
  }
  
  .profile2 .card-card-content-5 {
    align-items: center;
    display: flex;
    gap: 17.07px;
    left: 186px;
    width: 59px;
  }
  
  .profile2 .bg {
    background-color: var(--international-orange);
    border-radius: 28.47px;
    box-shadow: 0px 17.07px 42.67px #00000012;
    height: 57px;
    left: -5px;
    position: absolute;
    top: -5px;
    transform: rotate(90.23deg);
    width: 57px;
  }
  
  .profile2 .overlap-group-4 {
    background-image: url(../../assets/img/profileImgs/ellipse-77-5@2x.png);
    height: 53px;
    justify-content: flex-end;
    left: -3px;
    min-width: 53px;
    padding: 0.0px 3.4px;
    position: relative;
  }
  
  .profile2 .ellipse-78-4 {
    height: 30px;
    width: 23px;
  }
  
  .profile2 .flex-row {
    align-items: flex-start;
    gap: 18px;
    height: 45px;
    margin-right: 31.06px;
    min-width: 283px;
  }
  
  .profile2 .group-13973 {
    align-items: flex-start;
    align-self: flex-end;
    display: flex;
    min-width: 133px;
  }
  
  .profile2 .overlap-group3 {
    align-items: flex-start;
    background-image: url(../../assets/img/profileImgs/rectangle-2591.png);
    background-size: 100% 100%;
    display: flex;
    height: 44px;
    min-width: 131px;
    padding: 6.8px 18.0px;
  }
  
  .profile2 .learn-about-the-matching {
    min-height: 30px;
    width: 91px;
  }
  
  .profile2 .btn-learn-about-the-meetups {
    background-image: url(../../assets/img/profileImgs/rectangle-2591-1.png);
    background-size: 100% 100%;
    display: flex;
    height: 42px;
    width: 131px;
  }
  
  .profile2 .learn-about-the-meetups {
    height: 34.07px;
    margin-left: 18.0px;
    margin-top: 7.7px;
    width: 91.4px;
  }
  
  .profile2 .profile-summary {
    color: var(--black);
    font-family: var(--font-family-dm_sans);
    font-size: var(--font-size-xxxxl);
    font-weight: 700;
    letter-spacing: 0.96px;
    line-height: normal;
    margin-top: 20px;
    min-height: 35px;
    text-align: center;
    width: 205px;
  }
  
  .profile2 .span1 {
    font-size: var(--font-size-m);
    letter-spacing: 0.60px;
  }
  
  .profile2 .overlap-group16 {
    height: 425px;
    margin-left: 8.99px;
    margin-top: 7px;
    position: relative;
    width: 304px;
  }
  
  .profile2 .table-profile-summary {
    display: flex;
    height: 425px;
    left: 0;
    position: absolute;
    top: 0;
    width: 303px;
  }
  
  .profile2 .table-left-bgcolor {
    background-color: var(--international-orange);
    height: 424.69px;
    width: 181px;
  }
  
  .profile2 .table-right-bgcolor {
    background-color: var(--white);
    height: 424.69px;
    width: 122px;
  }
  
  .profile2 .your-passions-intere {
    left: 0;
    letter-spacing: 0.60px;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 8px;
    width: 175px;
  }
  
  .profile2 .overlap-group2 {
    height: 404px;
    left: 4px;
    position: absolute;
    top: 7px;
    width: 300px;
  }
  
  .profile2 .basketball-karate {
    color: var(--black);
    font-family: var(--font-family-dm_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    left: 184px;
    letter-spacing: 0.60px;
    line-height: normal;
    position: absolute;
    top: 0;
    width: 108px;
  }
  
  .profile2 .rectangle-2585 {
    background-color: var(--international-orange);
    height: 1px;
    left: 0;
    position: absolute;
    top: 27px;
    width: 297px;
  }
  
  .profile2 .rectangle-2586 {
    background-color: var(--international-orange);
    height: 1px;
    left: 1px;
    position: absolute;
    top: 48px;
    width: 297px;
  }
  
  .profile2 .rectangle-2587 {
    background-color: var(--international-orange);
    height: 1px;
    left: 1px;
    position: absolute;
    top: 74px;
    width: 297px;
  }
  
  .profile2 .rectangle-2588 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 100px;
    width: 297px;
  }
  
  .profile2 .rectangle-2589 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 126px;
    width: 297px;
  }
  
  .profile2 .rectangle-2590 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 152px;
    width: 297px;
  }
  
  .profile2 .rectangle-2591 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 178px;
    width: 297px;
  }
  
  .profile2 .rectangle-2592 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 209px;
    width: 297px;
  }
  
  .profile2 .rectangle-2593 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 239px;
    width: 297px;
  }
  
  .profile2 .rectangle-2594 {
    background-color: var(--international-orange);
    height: 1px;
    left: 2px;
    position: absolute;
    top: 266px;
    width: 297px;
  }
  
  .profile2 .rectangle-2595 {
    background-color: var(--international-orange);
    height: 1px;
    left: 3px;
    position: absolute;
    top: 292px;
    width: 297px;
  }
  
  .profile2 .rectangle-2596 {
    background-color: var(--international-orange);
    height: 1px;
    left: 3px;
    position: absolute;
    top: 344px;
    width: 297px;
  }
  
  .profile2 .rectangle-2597 {
    background-color: var(--international-orange);
    height: 1px;
    left: 3px;
    position: absolute;
    top: 317px;
    width: 297px;
  }
  
  .profile2 .flex-col {
    align-items: flex-end;
    flex-direction: column;
    gap: 13px;
    margin-left: 12.44px;
    min-height: 59px;
    width: 307px;
  }
  
  .profile2 .menu-bar {
    display: flex;
    height: 22px;
    margin-right: 3.46px;
    width: 106px;
  }
  
  .profile2 .apply-now {
    align-items: flex-start;
    display: flex;
    flex: 1;
    min-width: 108px;
  }
  
  .profile2 .overlap-group-5 {
    background-image: url(../../assets/img/profileImgs/rectangle-10-8@2x.png);
    height: 77px;
    justify-content: flex-end;
    margin-left: -27px;
    margin-top: -25.52px;
    min-width: 161px;
    padding: 28.5px 27.3px;
  }
  
  .profile2 .edit-profile {
    color: var(--white);
    font-family: var(--font-family-dm_sans);
    font-size: var(--font-size-xl);
    font-weight: 700;
    letter-spacing: 0.66px;
    line-height: normal;
    min-height: 16px;
    width: 94px;
  }
  
  .profile2 .span1-1 {
    font-size: 12px;
    letter-spacing: 0.72px;
  }
  
  .profile2 .menu-bar-container {
    align-items: flex-start;
    display: flex;
    gap: 34px;
    height: 24px;
    min-width: 307px;
  }
  
  .profile2 .menu-bar-1 {
    display: flex;
    height: 22px;
    width: 124px;
  }
  
  .profile2 .apply-now-1 {
    min-width: 126.36px;
  }
  
  .profile2 .overlap-group-6 {
    background-image: url(../../assets/img/profileImgs/rectangle-10-6@2x.png);
    height: 77px;
    justify-content: flex-end;
    margin-left: -27px;
    margin-top: -25.52px;
    min-width: 179px;
    padding: 28.5px 33.6px;
  }
  
  .profile2 .edit-profile-1 {
    width: 104px;
  }
  
  .profile2 .menu-bar-2 {
    align-self: flex-end;
    display: flex;
    height: 22px;
    width: 149px;
  }
  
  .profile2 .apply-now-2 {
    min-width: 151.44px;
  }
  
  .profile2 .overlap-group-7 {
    background-image: url(../../assets/img/profileImgs/rectangle-10-7@2x.png);
    height: 77px;
    justify-content: flex-end;
    margin-left: -27px;
    margin-top: -25.52px;
    min-width: 204px;
    padding: 28.5px 35.7px;
  }
  
  .profile2 .edit-profile-2 {
    width: 133px;
  }
  
  .profile2 .apply-now-3 {
    align-items: flex-start;
    display: flex;
    flex: 1;
  }
  
  .profile2 .card-card-content-6 {
    position: absolute;
    top: 0;
  }
  
  .profile2 .card-pre-build-8 {
    left: 0;
    position: absolute;
  }
  
  .profile2 .container-5 {
    background-color: var(--international-orange);
    border-radius: 28.47px;
    box-shadow: 0px 17.07px 42.67px #00000012;
    height: 57px;
    position: absolute;
    width: 57px;
  }
  
  .profile2 .dislike-3 {
    position: absolute;
    transform: rotate(90.23deg);
  }
  
  .profile2 .edit-profile-3 {
    letter-spacing: 0.60px;
    line-height: normal;
    min-height: 13px;
  }
  
  .profile2 .flex {
    display: flex;
    margin-top: 15px;
  }
  
  .profile2 .group-197-3 {
    display: flex;
    height: 46.08px;
    min-width: 46.08px;
    position: relative;
  }
  
  .profile2 .learn-about-the {
    color: var(--white);
    font-family: var(--font-family-dm_sans);
    font-size: var(--font-size-xxl);
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    text-align: center;
  }
  
  .profile2 .number-2 {
    color: var(--white);
    letter-spacing: 0;
    position: absolute;
    white-space: nowrap;
  }
  
  .profile2 .overlap-group-8 {
    align-items: flex-start;
    background-size: 100% 100%;
    display: flex;
  }
  
  .profile2 .percent-4 {
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
  }
  
  .profile2 .price-14 {
    letter-spacing: 0;
    line-height: normal;
  }
  
  .u-relative-flexbox {
    width: 80%;
    position:relative;
    display:flex;
    align-items:center;
  }
  
  .u-tbl > tbody > tr > td:nth-child(even) {
    background: white; 
    color: black;
    padding: 8px;
  }
  
  .u-tbl {
      border: 1px solid #FF5603;
      position: relative;
      display: table;
      width: 80%;
      margin: 16px auto;
      max-width: 500px;
      table-layout: fixed;
      border-collapse: separate;
  
  }
  
  .u-sticker--1 {
      position: absolute;
      top: initial;
      margin: 0 auto;
      z-index: 10100;
      display: block;
      left: 0;
      right: 0;
      bottom: 120px;
  }
  .u-sticker--1.is-hidden{
    display:none;
  }
  
  .profile2-container,
  .letsUpgradeYou-container {
    background-color: #1e1d22;
  }
  @media screen and (min-width:530px) { 
    .profile2-container,
    .letsUpgradeYou-container {
      
      /* here is that black color bg image
      url(../../assets/img/profileImgs/Desktop-2-bg.png) 0 0/100% auto;    */
      background-image: unset;
  
    }
   }
  
  
   .u-tbl {
    border: 1px solid #FF5603;
    position: relative;
    display: table;
    width: 80%;
    margin: 32px auto;
    max-width: 500px;
    table-layout: fixed;
    border-collapse: separate;
  }

  table.u-tbl,
  .u-tbl th, 
  .u-tbl tr {
    border-collapse: collapse !important;
    border: 1px solid #FF5603;
  }

  .u-tbl > tbody > tr > td:nth-child(odd) {
    background: #FF5603;
    color: white;
    padding: 8px;
    background: #fb6d26;
    font-weight: bold;
  }
  
  .u-tbl > tbody > tr > td:nth-child(even) {
    background: white; 
    color: black;
    padding: 8px;
  }
  .name-description {
    color: white;
    font-weight:bold;
    position: relative;
    z-index: 10;
    text-align:center;
    pointer-events: none;
  }
  .name-description p {
    font-size: 3vw;
    font-weight: bold;
    font-style: italic;
    color: #ffebd7;
    text-shadow: 2px 2px 2px #0000004f;
    pointer-events: none; 
}

@media(max-width: 854px) {
  .new-avatar-container-inner .name-description,
  .new-avatar-container-inner .name-description p {
    font-size: 16px;
    font-weight: bold;
  }
}


.new-avatar-userImg-containerbg {
  left: 0;
  right: 0;
  position: absolute;
  top: 20px;
  margin: 0 auto;
  z-index: 2;
  width: 60vw;
  height: auto;
  display:none;
  min-width:500px;
}


@media (max-width:768px) {
  .new-avatar-userImg-containerbg {
    top: 50px;
    min-width: 590px;
    height: 340px;
    overflow: hidden;
    max-height: unset;
  }
}
@media (max-width:500px) {
  .new-avatar-userImg-containerbg {
    top: 20px;
    min-width: 500px;
    height: auto;
    overflow: hidden;
    max-height: unset;
  }
}

.new-avatar-userImg-containerbg.is-visible {
  display:block;
}



  .howMatchingWorks .btn {
    display: block;
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin-left: 0;
    text-align: left;
  }

  .howMatchingWorks,
  .howMatchingWorks .letsUpgradeYou-container
    .letsUpgradeYou-container {
    background-image: url(../../assets/img/profileImgs/Desktop-2-bg.png) 0 0/100% auto;
    background-color: #ff5602;
    
    background-blend-mode: color-burn;
  }
  .letsUpgradeYou-container {
    background-color:black;
  }

  .img-rotate-right-wrap,
  .img-rotate-left-wrap {
    display: inline-block;
    width: 40%;
    overflow: hidden;
    position: relative;
    margin: 4px;
    vertical-align: top;
    height: 270px;
    border-radius: 16px;
    max-width: 187px;
    transform-origin: bottom;
    border: none;
  }
  .img-rotate-right-wrap {
    transform:rotateZ(5deg);
  }
  .img-rotate-left-wrap {
    transform:rotateZ(-5deg)
  }

  .heart-button-wrap {
    position: absolute;
    z-index: 1000;
    width: 190px;
    margin: -20px auto 0 auto;
    left: 0;
    right: 0;
    text-align: center;
}
.heart-button-wrap input[type=checkbox] {
  visibility: hidden;
}
.heart-button-wrap > .checkbox-label:nth-child(1) {
  transform: translate3d(-90px, 0, 0);
}
.heart-button-wrap > .checkbox-label:nth-child(2) {
  transform: translate3d(52px, 0, 0);
}

.img-rotate-right,
.img-rotate-left {
  height: 100%;
  filter: blur(10px);
  transition: filter 0.5s ease-in-out;
}
.img-rotate-left.blur-animation,
.img-rotate-right.blur-animation {
  animation: blurAnimation 5s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes blurAnimation {
  0% {
    filter: blur(10px);
  }
  20% {
    filter: blur(0);
  }
  80% {
    filter: blur(0);
  }
  100% {
    filter: blur(10px);
  }
}

.heart-button-wrap svg {
  background: radial-gradient(white, white, transparent, transparent, transparent);
  border-radius: 100%;
  border: none;
}
.YouHaveMatches { 
  background-color: #ff5602;
  color: white;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
/**
 * new-avatar
 * 
 */  
 .new-avatar,
 .new-avatar-img-top,
 .new-avatar-img-bottom,
 .new-avatar-userImg-container {
   position: absolute;
   display: block;
   width: 100%;
   height: auto;
   top: 0;
   left: 0;
   right: 0;
   z-index: 1; 
 }
 .new-avatar-img-top {
   z-index: 5;
 }
 .new-avatar-userImg-container {
   z-index: 4;
   width: 50%;
   margin: 50px auto;
 }
 .new-avatar-userImg {
   width: 100%;
   position: relative;
   top:0;
 }
 .new-avatar-img-bottom {
  z-index: 3;
 }
  
 .new-avatar-bg-gradient {
     width: 100%;
     height: 49vw;
     position: absolute;
     z-index: 1;
     display: block;
     background: radial-gradient(#fe8b27, #fa8a25);
 }
 .new-avatar--button-row {
  border-style: solid;
  border-color: #c0c0c057;
  border-width: 0 0 0.5px 0;
  padding-bottom: 18px;
 }
 .new-avatar-container-inner {
  position:relative;
  margin: 40vw auto 0 auto;
  margin-top: 40vw;
  left:0;
  right:0;
  z-index: 9;
 }

 @media (min-width: 768px){
  .new-avatar-container-inner{
    margin-top:23vw;
  }
 }


.button-section--heartJewelry,
.rotateY360deg {
  width: 120px;
  height: auto;
  margin:10px auto;
  left:0;
  right:0;
  animation: rotateYAxis 6s linear 4s infinite;

 }
 @keyframes rotateYAxis {
  0%   { transform: rotateY(0deg); }
  70%   { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
#CertContent1 input,
#CertContent1 textarea  {
  background: #fffffff2;
  border: none;
  border-radius: 22px 2px;
}

.new-avatar--button-row.personalityTest_button_collection {
  width: 20%;
  display: inline-block;
  margin: 1%;
}
.u-column--set {
  columns: 2 auto;
}
.style-js-BlackBackground .new-avatar--button-row {
  border: none;
}

.u-personalitySummary.container {
  position: relative;
  margin-top: 90px;
  background: linear-gradient(45deg, black, red);
  padding-top: 22px;
}
.u-personalitySummary.container .profile__stat--slider > .form-group {
  width: 85%;
}
.u-personalitySummary-darkbtn.btn {
  width: 300px;
  margin: 28px auto;
  position: relative;
  top: 40px;
  background: linear-gradient(37deg,#f68640, #ff5602);
  cursor: pointer;
}

.custom-tooltip {
  position: fixed;
  z-index: 10;
  background: white;
  padding: 10px 18px;
  border: 1px solid #ccc;
  border-radius: 24px;
  min-width: 280px;
  max-width:400px;
  color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 4px 6px #00000045;
}

.profile__stat--icons .btn.btn-secondary {
  background: transparent;
  border: none;
  border-radius: 100%;
}

.btn.slider-btn-save {
  background: darkgreen;
    margin: 32px auto;

}
@media (max-width:768px) {
  .button-section .name-description p {
    height:44px;
    min-height:44px;
    font-size: 24px;
  }
  .u-personalitySummary.container > .row {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-personalitySummary.container .tab-pane {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

}

@media (max-width:468px) {

  .u-personalitySummary.container > .row {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
  .u-personalitySummary.container .tab-pane {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }
}

.profile-details-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (min-width: 960px) {
  .profile-details-video-container {
    flex-direction: row;
  }
}
 
.profile-details-video-container .video-item {
  margin: 10px; /* Adjust as needed */
}

/*
.profile-bet .img-wrapper {
  display:none; 
}
*/
.profile-bet footer {
  background:transparent; 
}
.profile-bet h4 {
  font-weight:bold;
  text-align:center;
  color:white;
}
@media (max-width: 992px) {
  .profile-bet #signin {
      padding-top: 140px;
  }
}


         

/* character animaton */
/* CSS */
/* Adding an idle state */

.player-animation .animated-character--wrap {
  animation-delay:0s;
  animation-duration:0s;
  animation-direction:normal;
  animation-fill-mode: forwards;
}

.img--wakanda-coins {
  width:150px;
}
.wakandacoins-inline {
  display: inline-block;
}
.wakandacoins-inline--img {
  display:block;
  width:100px;
  height: auto;
}
.morebets__header {
  display:block;
  text-align:center;
  color:white;
  font-weight:bold;
}

.morebets__link-wrap {
  border-radius: 80% 80% 0 0;
  background: linear-gradient(45deg, #fc8a26, #EF6C00);
  
  margin: 8px;
  min-width: 140px;
  max-width: 200px;
  flex-grow: 1;
  text-align: center;
  max-height: 180px;
  overflow: hidden;
}
.morebets__link {
  width:140px;
  height: 140px;
  margin: 8px 0; 
  
}
.morebets__uname {
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  z-index: 10;
  text-align: center;
  width: 100%;
  margin: 0;
  background: linear-gradient(138deg, #ef6c0054, #ef6c00f2);
  left: 0;
  right: 0;
  
}

.morebets__img {
  width:100%;
  height: auto;
  position:relative;
  display:block;
  margin-top: 10px;

 }
 
.wonOrLost-notification,
.successful-notification {
  background: #4CAF50;
  color: snow;
  font-weight: bold;
  display: none;
  border-radius: 4px;
  padding: 8px 16px;
}
.successful-notification.is-visible {
  display:block;
}
.wonOrLost-notification.is-won {
  background:  #4CAF50;
}
.wonOrLost-notification.is-loss {
  background:   darkred;
}


     

/*
.player-animation-idle .animated-character--wrap.nine-steps {
   
  animation: animation1 0s linear 1533ms, animation2 0s linear 3066ms, animation3 0s linear 4599ms, animation4 0s linear 6132ms; 
}

 

@keyframes idle {
  to {
    transform: translateY(0);
  }
}

@keyframes animation1 {
  to {
    transform: translateY(47px);
  }
}

@keyframes animation2 {
  to {
    transform: translateY(107px);
  }
}

@keyframes animation3 {
  to {
    transform: translateY(167px);
  }
}


@keyframes animation4 {
  to {
    transform: translateY(227px);
  }
}


@keyframes animation5 {
  to {
    transform: translateY(287px);
  }
}
@keyframes animation6 {
  to {
    transform: translateY(347px);
  }
}
@keyframes animation7 {
  to {
    transform: translateY(401px);
  }
}
@keyframes animation8 {
  to {
    transform: translateY(461px);
  }
}
@keyframes animation9 {
  to {
    transform: translateY(461px);
  }
}


.player-animation-idle .animated-character--wrap {
 
  animation-name: idle; 
}
.player-animation-1 .animated-character--wrap {
  animation-name: animation1; 
}
.player-animation-2 .animated-character--wrap {
  animation-name: animation2; 
}
.player-animation-3 .animated-character--wrap {
  animation-name: animation3; 
}
.player-animation-4 .animated-character--wrap {
  animation-name: animation4; 
}
.player-animation-5 .animated-character--wrap {
  animation-name: animation5; 
}
.player-animation-6 .animated-character--wrap {
  animation-name: animation6; 
}
.player-animation-7 .animated-character--wrap { 
  animation-name: animation7; 
}
.player-animation-8 .animated-character--wrap { 
  animation-name: animation8; 
}
.player-animation-9 .animated-character--wrap { 
  animation-name: animation9; 
}
*/
/*

Okay let's review the specific timings and how to make them perfect. 
 
STEP #1: IDLE  transformThe player character waits at the top button idle waiting for the button . 
1. the button closes. command is sent. It seems to require 2 seconds to complete the close animation based on this code in the child component that manages the closing activity. 

   useEffect(() => {
      if (ref) {
        ref.current = {
          circleRef:circleRef,
          changePercent: (newPercent, closeOrOpen) => {
            const circle = circleRef.current;
            const percent = newPercent / 100;
            const calculated = 151 - 151 * percent;
            circle.setAttribute('percentcompleted', `${newPercent}%`);
            circle.querySelector('.circle-green').style.strokeDashoffset = calculated;
       
              setTimeout(function(){
                let elmRoot =circle.classList.contains('u-js-animation-parent')? circle : circle.closest('.u-js-animation-parent');
                 if (closeOrOpen==='open') {
                  elmRoot.classList.remove('is-closed');
                 } else if (closeOrOpen==='close') {
                  elmRoot.classList.add('is-closed');
                  let webmElm = elmRoot.querySelector('.gog-js-iconResult');
                  if (webmElm) {
                    webmElm.play();
                  }
                 }
                 // console.log('elmRoot=',elmRoot,' closeOrOpen=',closeOrOpen,' circle=',circle);
              },2000);
          },
        };
       // console.dir(ref.current);
        ref(ref.current);
      }
    }, [ref]);

Then when the is-closed class is applied, the css takes 200ms to close it as seen here 

.u-personalitytest--bar {
    position: relative;
    display: block;
    left: 0;
    height: 38px;
    font-family: 'DM Sans', Helvetica;
    width: 244px;
    background: #FF5603;
    border-radius: 8px;
    transition: width 200ms ease-in 0s;
    color: white;
    -webkit-font-feature-settings: "smcp", "c2sc";
    font-feature-settings: "smcp", "c2sc";
    font-variant: all-small-caps;
    font-weight: bold;
    font-size: 16px;
    margin: 8px 0px 0px 0px;
    background: linear-gradient(37deg,#f68640, #ff5602);
    width: 100%;
    cursor: pointer;

Therefore from the time the command is sent to changePercent(...) requires 2300ms
 
2. the video character animation plays a jumping animation. video duration 1.533
It should begin jumping as soon as the button starts to move. 
 
3. The transformation is supposed to be instantly made down by ~60 pixels. to repeat the jumping animation again.  So it should ideally be moving every single 1.533 seconds to start at the beginning.  
 
It would appear that i should make changePercent have a wait time of 1.533 so that it will be in sync with the rest of the animations. That would be my strategy. What do you think of this to keep everything in sync. 

*/


/* Chrome  
input[type='range']::-webkit-slider-thumb {
-webkit-appearance: none;
width: 20px;
height: 20px;
background: red;
cursor: pointer;
}

 Firefox  
input[type='range']::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: red;
  cursor: pointer;
}

  IE 
input[type='range']::-ms-thumb {
  width: 20px;
  height: 20px;
  background: red;
  cursor: pointer;
}*/