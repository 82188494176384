.slider-wrapper {
  position: relative;

  .onboarding-slider {
    background: linear-gradient(180deg, #fca34d 0%, #ff5603 100%), #7950f2;

    @media (max-width: 768px) {
      display: none;
    }
    .onboarding-slider-item {
      justify-content: space-between;
      align-items: center;
      min-height: 100vh;
      position: relative;

      .left-side {
        margin-bottom: 250px;
        padding-left: 10%;
        h3 {
          font-weight: 700;
          font-size: 40px;
          line-height: 120%;
          color: #000000;

          @media (max-width: 1024px) {
            font-size: 32px;
          }
        }

        p {
          font-family: "Montserrat";
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          color: #ffffff;
          // width: 90%;
          margin-top: 30px;

          @media (max-width: 1024px) {
            font-size: 16px;
          }
        }
      }

      

      .nested-slider {
        max-width: 360px;
        margin: 0 auto;

        .slick-prev {
          left: 0px;
          z-index: 999;
        }
        .slick-next {
          right: 0px;
          z-index: 999;
        }
        .nested-slider-item {
          display: flex !important;
          align-items: center;
          justify-content: center;

          .nested-slider-img {
            max-width: 300px;
          }
        }
      }

      .duel-nested-slider {
        max-width: 650px;
        margin: 0 auto;

        .slick-prev {
          left: 0px;
          z-index: 999;
        }
        .slick-next {
          right: 0px;
        }
        .nested-slider-item {
          display: flex !important;
          align-items: center;
          justify-content: center;

          .nested-slider-img {
            max-width: 280px;
            height: auto;

            @media (max-width: 1300px) {
              max-width: 250px;
            }
          }
        }
      }
    }

    .prev-arrow,
    .next-arrow {
      position: absolute;
      bottom: 15%;
      transform: translateY(-50%);
      background-color: #fe8643;
      border: none;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;
      z-index: 999;
      height: 60px;
      width: 60px;
      border-radius: 12px;
      transition: linear 0.3s;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: #ff9228;
      }
    }

    .prev-arrow {
      left: 220px;
    }

    .next-arrow {
      left: 290px;
    }

    .slick-dots {
      // max-width: 350px;
      bottom: 28%;
      left: 180px;
      transform: translateY(-50%);
      // width: 100%;
      text-align: left;

      li {
        width: 20px !important;
        margin: auto !important;
        button {
          width: auto !important;
          &::before {
            content: "";
            opacity: 1;
            width: 14px;
            height: 14px;
            line-height: 14px;
            background: #fff;
            border-radius: 50px;
            box-shadow: 0px 6px 5px 0px #00000061;
          }
        }

        &.slick-active {
          width: 40px !important;
          margin-right: 5px !important;
          button {
            &::before {
              width: 40px !important;
              background: #ff5603;
            }
          }
        }
      }
    }
  }

  .m-onboarding-slider {
    display: none;
    overflow-x: hidden;
    background-color: #000;

    @media (max-width: 768px) {
      display: block;
    }

    .m-slider-item {
      position: relative;
      background-position: top;
      background-size: cover;
      min-height: 100vh;
      padding: 0 30px;

       @media (max-width: 576px) and (max-height:568px) {
        // height: 570px;
       }

      @media (max-width: 320px) {
        padding: 0 20px;
      }

      .content-area {
        position: absolute;
        top: 56.5%;
        margin-right: 40px;
        z-index: 2;

        @media (max-width: 576px) and (max-height:568px) {
          top: 53%;
        }

        h4 {
          color: #fff;
          font-size: 24px;
          font-family: Poppins !important;
          font-style: italic;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 10px;

          @media (max-width: 320px) {
            font-size: 20px;
          }

          .first-slider-title {
            font-size: 36px !important;
            line-height: 40px;
          }

          .mobile-title-text {
            color: #fca34d !important;
          }
        }

        .divider {
          background-color: #d9dfeb;
          height: 3px;
          border-radius: 12px;
          border-top: 0;
          margin-top: 5px;
          margin-bottom: 10px;
        }

        p {
          color: #fff;
          font-size: 14px;
          font-family: Poppins !important;
          font-weight: 400;
          line-height: 18px;

          @media (max-width320px) {
            font-size: 13px;
          }

          .mobile-title-text {
            color: #fca34d !important;
            font-weight: 600;
          }
        }
      }

      .dark-overlay {
        position: absolute;
        width: 100%;
        height: 40%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.66) 14.86%,
          #0b0300 75.52%
        );
        z-index: 1;
      }

      .nested-slider {
        max-width: 280px;
        margin: 0 auto;
        top: 70px;

        @media (max-width:350px) {
              max-width: 200px;
            }

        .slick-prev {
          left: 0px;
          z-index: 999;
        }
        .slick-next {
          right: 0px;
        }
        .nested-slider-item {
          display: flex !important;
          align-items: center;
          justify-content: center;

          .nested-slider-img {
            max-width: 218px;

            @media (max-width:350px) {
              max-width: 150px;
            }
          }
        }
      }
    }

    .duel-nested-slider {
      max-width: 650px;
      margin: 0 auto;
      top: 150px;

      @media (max-width:350px) {
              top: 100px;
            }

      .slick-prev {
        left: 0px;
        z-index: 999;
      }
      .slick-next {
        right: 0px;
      }
      .nested-slider-item {
        display: flex !important;
        align-items: center;
        justify-content: center;

        .nested-slider-img {
          max-width: 230px;
          height: auto;
        }
      }
    }

    .prev-arrow,
    .next-arrow {
      position: absolute;
      bottom: 5%;
      transform: translateY(-50%);
      background-color: #fe8643;
      border: none;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;
      z-index: 999;
      height: 60px;
      width: 60px;
      border-radius: 12px;
      transition: linear 0.3s;

      @media (max-width: 320px) {
        padding: 0px;
        height: 40px;
        width: 40px;
        border-radius: 8px;
        font-size: unset;
      }

      &:hover {
        background-color: #ff9228;
      }
    }

    .prev-arrow {
      right: 105px;

      @media (max-width:350px) {
        right: 90px;
      }
    }

    .next-arrow {
      right: 35px;
    }

    .slick-dots {
      // max-width: 350px;
      bottom: 9%;
      left: 30px;
      transform: translateY(-50%);
      // width: 100%;
      text-align: left;

      @media (max-width:350px) {
        bottom: 6%;
      }

      li {
        width: 14px !important;
        margin: auto !important;
        button {
          width: auto !important;
          &::before {
            content: "";
            opacity: 1;
            width: 8px;
            height: 8px;
            line-height: 8px;
            background: #fff;
            border-radius: 50px;
          }
        }

        &.slick-active {
          width: 25px !important;
          margin-right: 5px !important;
          button {
            &::before {
              width: 23px !important;
              background: #ff5603;
            }
          }
        }
      }
    }
  }

  .login-link {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    position: absolute;
    bottom: 12%;
    left: 225px;
    transition: linear 0.3s;
    font-weight: 600;

    &:hover {
      color: #000000;
    }

     @media (max-width: 320px) {
      bottom: 4% !important;
    }

    @media (max-width: 767px) {
      bottom: 6%;
      left: 40px;

      &:hover {
        color: #fe8643;
      }
    }
  }
}
