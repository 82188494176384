.visualRewards {
    width:100%;
    max-width: 350px;
    margin:8px auto;
    height: auto;
    position:relative;
    transform-style: flat;
    padding: 16px;
    display:block;
    visibility:hidden;
    background: linear-gradient(137deg,#ff5602, #f68640, #ff5602);
    border-radius: 8px;
}
.visualRewards__coins {
    width: 100px;
    height: 100px;
    position: relative; 
    transform: translate3d(0,0,0);
    z-index: 10;
    opacity: 1;
}

.visualRewards .visualRewards__coins {
    animation-play-state: paused;
}

.visualRewards.animationRunning .visualRewards__coins{
    animation-play-state: running;
}

 
.visualRewards.animationRunning {
    visibility:visible;
}
.visualRewards__coin-1,
.visualRewards__coin-2,
.visualRewards__coin-3 {
    margin: 0;
    padding:0;
    /* 
    animation: coin1 1s ease-in 0s infinite;
    animation: coin2 1s ease-in 200ms infinite; 
    animation: coin3 1s ease-in 400ms infinite;
    */
}
 
@keyframes coin1 {
    0% {
        transform: translate(0, 0, 0);
        opacity: 0;
    }
    10% {
        opacity: 1;
        transform: translate(10px, 10px, 0);
    }
    80% {
        transform: translate(0, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate(10px, 10px, 0);
        opacity: 1;
    }
}
.visual-rewards__gog-js-iconResult {
    transform: translate(0, 0, 0);
        opacity: 0;
    animation: iconBounce 3s ease-in 0s infinite forwards;
}
#toolTipIcon-ptest,
#toolTipIcon-ptest2 {
    width: 50%;
    height:auto;
}
 
@keyframes iconBounce {
    0% {
        transform: translate(0, 0, 0);
        opacity: 0;
    }
    10% {
        opacity: 1;
        transform: translate(0, -10px, 0);
    }
    80% {
        transform: translate(0, 10px, 0);
        opacity: 1;
    }
    100% {
        transform: translate(0, 10px, 0);
        opacity: 1;
    }
}

.visualRewards__msg {
    position: relative;
    color: lightyellow;
    font-weight: bold;
    font-size: 18px;
    display: block;
   /*  
   put this back for the onEnded event feature 
   visibility: hidden; 
    */

   }
   .visualRewards__msg.is-visible {
    visibility: visible;
   }