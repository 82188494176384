.new-header {
  background-image: url("../../assets/images/new-header-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img{
      @media (max-width:576px) {
        width: 180px !important;
      }
    }

    .login-btn {
      text-decoration: none;
      padding: 12px 30px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      background: #ff5603;
      color: #fff;
      font-size: 16px;
      font-family: Montserrat;
      font-weight: 700;
      line-height: 16px;

      @media (max-width:576px) {
        padding: 10px 20px;
      }
    }
  }
}


.hero-slider-wrapper{
  position: relative;
  background-color: #000000eb;
  overflow-x: hidden;
  height: 80vh;
  height: calc(100vh - 100px);
  overflow-y: hidden;


  .content-wrapper {
    text-align: center;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 40px;

    .hero-title {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width:768px) {
        flex-direction: column;
      }
      h2 {
        color: #fff;
        text-align: center;
        font-size: 70px;
        font-family: Montserrat;
        font-weight: 700;
        line-height: 120%;

         @media (max-width:768px) {
        font-size: 52px;
      }

      @media (max-width:576px) {
        font-size: 36px;
      }

      }
    }
  }


  .dark-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 1;
    opacity: 0.1;
  }
}
