.home-slider {
  background: linear-gradient(180deg, #fca34d 0%, #ff5603 100%), #7950f2;
  padding-top: 100px;
  padding-bottom: 50px;

  @media (max-width: 1187px) {
    padding-bottom: 100px;
    padding-top: 60px;
  }

  .home-slider-container {
    padding-bottom: 80px;

    @media (max-width: 1187px) {
       .intro-video {
        text-align: center; 
      }
      .bottom-width-mobile-view {
        width:90%;
      }
    }

    .home-slider-item {

      @media (max-width: 1187px) {
        flex-direction: column-reverse;
      }

      h3 {
        color: #000;
        margin-bottom: 30px;

        @media (max-width: 1024px) {
          margin-bottom: 15px;
        }

        @media (max-width: 1187px) {
              margin-top: 50px !important;
            }

    
      }
    }
  }

  .prev-arrow,
  .next-arrow {
    /* position: absolute;
    transform: translateY(-50%); */
    bottom: -45px;
    
    background-color: #fe8643;
    border: none;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    z-index: 999;
    height: 60px;
    width: 88px;
    border-radius: 12px;
    transition: linear 0.3s;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: #ff9228;
    }
  }

  .prev-arrow {
    left: 300px;
    display: none;

    @media (max-width: 1200px) {
      left: 200px;
    }

    @media (max-width: 768px) {
      left: 100px;
    }
  }

  .next-arrow {
    right: 25%;

    @media (max-width: 1200px) {
      right: 20%;
    }

    @media (max-width: 1024px) {
      // right: 215px;
    }

    @media (max-width: 768px) {
      right: 10%;
    }

    @media (max-width: 576px) {
      right: 5%;
    }
  }

  .slick-dots {
    // // max-width: 350px;
    // bottom: 50px;
    // left: 180px;
    // transform: translateY(-50%);
    // // width: 100%;
    // text-align: left;
    // margin-top: 200px;

    // margin-bottom: 30px;

    position: unset;
    position: initial;
    width: auto;
    margin: 0 10px 0 0;

    @media (max-width: 375px) {
      text-align: left;
    }

    li {
      width: 30px !important;
      margin: auto !important;

      @media (max-width: 576px) {
        width: 16px !important;
      }

      button {
        width: auto !important;
        &::before {
          content: "";
          opacity: 1;
          width: 14px;
          height: 14px;
          line-height: 14px;
          background: #fff;
          border-radius: 50px;
          box-shadow: 0px 6px 5px 0px #00000061;

          @media (max-width: 576px) {
            width: 8px;
            height: 8px;
          }
        }
      }

      &.slick-active {
        width: 40px !important;
        margin-right: 15px !important;

        @media (max-width: 576px) {
          width: 23px !important;
          margin-right: 8px !important;
        }
        button {
          &::before {
            width: 40px !important;
            background: #ff5603;

            @media (max-width: 576px) {
              width: 23px !important;
            }
          }
        }
      }
    }
  }
}
